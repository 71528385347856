export default {
  getLisintg(state) {
    return state.listing;
  },
  getListingFilterSearch(state) {
    return state.listingFilter.search;
  },
  getListingFilterType(state) {
    return state.listingFilter.type;
  },
  getListingFilterClient(state) {
    return state.listingFilter.clientId;
  },
  getListingFilterColumns(state) {
    return state.listingFilter.columns;
  },
  getListingFilterDriver(state) {
    return state.listingFilter.driverId;
  },
  getListingFilterSortOrder(state) {
    return state.listingFilter.sortOrder;
  },
  getListingFilterDate(state) {
    return state.listingFilter.date;
  },
  getListingFilterDateFrom(state) {
    return state.listingFilter.dateFrom;
  },
  getListingFilterDateUntil(state) {
    return state.listingFilter.dateUntil;
  },
  getListingFilterService(state) {
    return state.listingFilter.service;
  },
  getListingTotal(state) {
    return state.listingTotal;
  }
};

import * as actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import defaultState from "./state";

export default {
  namespaced: true,
  state: Object.assign({}, defaultState()),
  actions,
  mutations,
  getters
};

import { captureError } from "@/plugins/error-tracking";
import Services from "@/services/services";
import router from "@/router.js";
import ServiceStatus from "@/constants/services/status";
import * as mutation from "./mutations-types";

export function getServices({ commit }, request) {
  return new Promise((resolve, reject) => {
    Services.listing(request)
      .then(({ data, meta }) => {
        commit(mutation.ADD_SERVICES, data);
        if (meta && meta.skip === 0) {
          commit(mutation.UPDATE_TOTAL_ITEMS, meta.count);
        }
        resolve({ data, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function cancelService({ commit }, data) {
  return new Promise((resolve, reject) => {
    Services.cancel(data)
      .then(res => {
        if (res && res.response) {
          commit(mutation.REMOVE_SERVICE, data.id);
          resolve(res);
        } else {
          reject();
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function completeService({ commit }, id) {
  return new Promise((resolve, reject) => {
    Services.finish(id)
      .then(res => {
        if (res.data.status === 200) {
          commit(mutation.REMOVE_SERVICE, id);
          resolve(res);
        } else {
          reject();
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function archiveService({ commit }, id) {
  return new Promise((resolve, reject) => {
    Services.changeArchivedStatus({ serviceId: id })
      .then(res => {
        commit(mutation.REMOVE_SERVICE, id);
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function assignService({ commit }, { serviceId, driverId }) {
  return new Promise((resolve, reject) => {
    Services.assignService(serviceId, driverId)
      .then(res => {
        commit(mutation.UPDATE_SERVICE, {
          id: serviceId,
          driverId,
          status: ServiceStatus.NUM_ACTIVE
        });
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function reassignService({ commit }, { serviceId, driverId }) {
  return new Promise((resolve, reject) => {
    Services.reassignService(serviceId, driverId)
      .then(res => {
        commit(mutation.UPDATE_SERVICE, {
          id: serviceId,
          driverId,
          status: ServiceStatus.NUM_ACTIVE
        });
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function handleIncomingServiceUpdate({ state, commit }, { serviceId }) {
  if (router.currentRoute.query.tab !== "multi-punto") {
    commit(mutation.TOGGLE_UPDATE_INDICATOR, true);
    return;
  }

  const index = state.services.findIndex(_ => _.id == serviceId);
  if (index < 0) {
    commit(mutation.TOGGLE_UPDATE_INDICATOR, true);
    return;
  }

  const where = { id: serviceId, archived: false };
  if (state.clientId) where.clientId = state.clientId;
  if (state.vehicleType) where.vehicleType = state.vehicleType;
  where.status = [
    ServiceStatus.NUM_CREATED,
    ServiceStatus.NUM_REQUESTING,
    ServiceStatus.NUM_ACTIVE,
    ServiceStatus.NUM_SCHEDULED,
    ServiceStatus.NUM_DRIVERS_NOT_AVAILABLE
  ];

  Services.listing({ where })
    .then(({ data: services }) => {
      if (services.length > 0) {
        commit(mutation.UPDATE_SERVICE, services[0]);
      } else {
        commit(mutation.TOGGLE_UPDATE_INDICATOR, true);
      }
    })
    .catch(err => {
      console.log("Caught error:", err);
      captureError(err);
    });
}

import { captureError } from "@/plugins/error-tracking";
import Incidence from "@/services/serviceIncidence";

export function validate({}, id) {
  return new Promise((resolve, reject) => {
    Incidence.validate(id)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Warehouses");

const warehouses = Object.assign(
  {},
  {
    relation: "",
    listing({ filter = {}, where = {} }) {
      const params = {};
      if (Object.keys(filter).length) {
        params.filter = filter;
      }
      if (Object.keys(where).length) {
        params.where = where;
      }
      return loopback.get("/Warehouses/listing", {
        params
      });
    },

    listingByCity(filter, where) {
      const params = { filter, where };

      return loopback.get("/Warehouses/listingByCity", { params });
    },

    requestCollectionService(id, parcelIds) {
      return loopback.post(`/Warehouses/${id}/requestCollectionService`, {
        parcelIds
      });
    },

    collectionListing(id, { filter = {}, where = {} }) {
      const params = {};
      if (Object.keys(filter).length) {
        params.filter = filter;
      }
      if (Object.keys(where).length) {
        params.where = where;
      }
      return loopback.get(`/Warehouses/${id}/collectionServices`, {
        params
      });
    },
    deliveriesListing(id, { filter = {}, where = {} }) {
      const params = {};
      if (Object.keys(filter).length) {
        params.filter = filter;
      }
      if (Object.keys(where).length) {
        params.where = where;
      }
      return loopback.get(`/Warehouses/${id}/deliveryServices`, {
        params
      });
    },
    requestDeliveryService(id, data) {
      return loopback.post(`/Warehouses/${id}/requestDeliveryServices`, data);
    },
    location(id) {
      return loopback.get(`/Warehouses/${id}/Location`);
    },
    /**
     * @param {string} warehouseId Warehouse ID
     * @param data Sacan parcel data
     * @param data.safeParcelId Parcel ID w/o dashes
     */
    scanParcel(warehouseId, data) {
      return loopback.post(`/Warehouses/${warehouseId}/scanParcel`, data);
    },

    collectionRoutes(id, availableDrivers) {
      return loopback.post(`Warehouses/${id}/collectionRoutes`, {
        availableDrivers
      });
    },

    deliveryRoutes(id, availableDrivers) {
      return loopback.post(`Warehouses/${id}/deliveryRoutes`, {
        availableDrivers
      });
    }
  },
  modelObject
);

export default warehouses;

import DateConstants from "@zubut/common/src/constants/filters/date";
import LayoutType from "@/constants/serviceCenter/layout-type";

const defaultState = {
  reservations: [],
  created: [],
  assigned: [],
  active: [],
  completed: [],
  filters: {
    search: "",
    sortBy: "createdAt",
    sortOrder: "desc",
    vehicleType: null,
    date: DateConstants.NUM_TODAY,
    alert: null,
    clientId: [],
    status: null,
    from: null,
    until: null,
    page: 1
  },
  totalItems: 0,
  hasUpdates: false,
  layout: LayoutType.NUM_KANBAN
};

export default defaultState;

export default {
  filter(state) {
    return state.filter;
  },

  date(state) {
    return state.date;
  },

  selectedServiceHasIncidences(state) {
    if (state.selected.incidences && state.selected.incidences.length > 0) {
      return true;
    }
    return false;
  },

  getServices(state) {
    return state.services;
  },

  paymentMehodFilter(state) {
    return state.filter.paymentMethod;
  }
};

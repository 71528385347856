import Clients from "@/services/clients";
import store from "@/store";
import { captureError } from "@/plugins/error-tracking";
import Vue from "vue";
import NotificationsAccess from "@/constants/access/notifications";

const createSocketInstance = () =>
  require("engine.io-client")(process.env.VUE_APP_API_URL);

let socket = null;

let clientClosed = false;

const init = () => {
  if (
    !Vue.prototype.$ability.can(
      NotificationsAccess.READ_NOTIFICATIONS,
      NotificationsAccess.moduleName
    )
  )
    return;
  console.log("init");

  if (!socket) {
    console.log("init create");
    socket = createSocketInstance();
  }

  socket.on("open", () => {
    const request = {
      userId: Clients.getCurrentId(),
      socketEvent: "connect"
    };
    if (request.userId) {
      request.accessToken = JSON.parse(
        localStorage.getItem("loopback-token")
      ).id;
      const requestData = JSON.stringify(request);
      socket.send(requestData);
    }

    socket.on("message", message => {
      const jsonData = JSON.parse(message);
      const data = jsonData.data || {};
      if (process.env.NODE_ENV == "development") {
        console.log("message:event", jsonData.socketEvent);
        console.log("message:data", jsonData.data);
      }
      switch (jsonData.socketEvent) {
        case "clearService":
          store.dispatch("notification/notify", jsonData.data);
          break;
        case "connected":
          console.log("respuesta: ", data.data);
          store.dispatch("auth/updateConnectState", true);
          break;
        case "newUserNotification":
        case "updateNotifications":
        case "updateDriverPosition":
        case "newAdminNotification":
          store.dispatch(`notification/${jsonData.socketEvent}`, data);
          break;
        case "clientClosed":
          clientClosed = true;
          break;
        default:
          store.dispatch(`request/${jsonData.socketEvent}`, data);
      }
    });

    socket.on("close", reason => {
      console.log("sockets are closed");
      store.dispatch("auth/updateConnectState", false);
      switch (reason) {
        case "transport close":
          if (!clientClosed) {
            console.log("transport close");
            store
              .dispatch("auth/reconnectServer")
              .then(() => {
                console.log("reload");
                init();
              })
              .catch(err => {
                captureError(err);
                console.log("fail reconnect", err);
              });
          }
          break;
        default:
          console.log("unhandled close reason", reason);
          break;
      }
      closeSocket();
    });
  });
};

const closeSocket = () => {
  if (socket) {
    socket.close();
  }
  socket = null;
  clientClosed = false;
};

export default { init, closeSocket };

const NEW = "Nuevo";
const PENDING = "Pendiente";
const APPROVED = "Aprobado";
const DENIED = "Denegado";
const BILLING_REVISION = "Datos de Facturación por revisar";
const DOCS_REVISION = "Documentos por Revisar";
const ACTIVE = "Activo";
const BLOCKED = "Bloqueado";

const NUM_NEW = 0;
const NUM_PENDING = 1;
const NUM_APPROVED = 2;
const NUM_DENIED = 3;
const NUM_BILLING_REVISION = 4;
const NUM_DOCS_REVISION = 5;
const NUM_ACTIVE = 6;
const NUM_BLOCKED = 7;

const get = {};
get[NUM_NEW] = NEW;
get[NUM_PENDING] = PENDING;
get[NUM_APPROVED] = APPROVED;
get[NUM_DENIED] = DENIED;
get[NUM_BILLING_REVISION] = BILLING_REVISION;
get[NUM_DOCS_REVISION] = DOCS_REVISION;
get[NUM_ACTIVE] = ACTIVE;
get[NUM_BLOCKED] = BLOCKED;

const filterOptions = [
  { value: NUM_NEW, text: NEW },
  { value: NUM_PENDING, text: PENDING },
  { value: NUM_APPROVED, text: APPROVED },
  { value: NUM_ACTIVE, text: ACTIVE },
  { value: NUM_DENIED, text: DENIED },
  { value: NUM_BLOCKED, text: BLOCKED }
];

export default {
  get,
  filterOptions,
  NEW,
  PENDING,
  ACTIVE,
  BLOCKED,
  APPROVED,
  DENIED,
  BILLING_REVISION,
  DOCS_REVISION,
  NUM_NEW,
  NUM_PENDING,
  NUM_APPROVED,
  NUM_DENIED,
  NUM_BILLING_REVISION,
  NUM_DOCS_REVISION,
  NUM_ACTIVE,
  NUM_BLOCKED
};

import ClientStatus from "@/constants/clients/status";
import { captureError } from "@/plugins/error-tracking";
import Clients from "@/services/clients";
import UserAddresses from "@/services/user-addresses";
import * as mutation from "./mutations-types";
import { formatSendPhone } from "@zubut/common/src/utils/strings";

export function getClients({ state, commit }) {
  return new Promise((resolve, reject) => {
    Clients.getAll({})
      .then(clients => {
        commit(mutation.SET_CLIENTS, clients);
        resolve(clients);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getClientsFilter({ state, commit }, where) {
  return new Promise((resolve, reject) => {
    Clients.nameListing({
      filter: { skip: 0, limit: 5, order: "lastLogin DESC" },
      where
    })
      .then(clients => {
        commit(mutation.SET_CLIENTS_FILTER, clients.data);
        resolve(clients);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getListing({}, filter) {
  return new Promise((resolve, reject) => {
    Clients.listing(filter)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function create({ state, commit }) {
  return new Promise((resolve, reject) => {
    const formState = state.create;
    const newClient = {
      email: formState.email,
      password: formState.password,
      name: formState.companyName,
      contact: formState.contactName,
      phone: formatSendPhone(formState.phone),
      type: formState.type,
      businessLine: formState.businessLine,
      status: ClientStatus.NUM_ACTIVE
    };

    Clients.createClient(newClient)
      .then(res => {
        commit(`${mutation.RESET_CLIENT_CREATION}`);
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function createBranch({ state, commit }) {
  return new Promise((resolve, reject) => {
    const formState = state.create;
    const newClient = {
      address: formState.address.address,
      email: formState.email,
      position: formState.address.position,
      password: formState.password,
      name: formState.companyName,
      contact: formState.contactName,
      phone: formState.phone,
      type: formState.type,
      information: formState.addressReferences,
      status: ClientStatus.NUM_ACTIVE
    };
    const clientId = formState.parentCompany;

    Clients.createBranch(clientId, newClient)
      .then(res => {
        commit(`${mutation.RESET_CLIENT_CREATION}`);
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getBranchListing({}, filter) {
  return new Promise((resolve, reject) => {
    Clients.branchListing(filter)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function addBranch({}, { companyId, data }) {
  return new Promise((resolve, reject) => {
    Clients.addBranch(companyId, data)
      .then(res => {
        if (res.companyId && res.companyId === companyId) {
          resolve(res);
        } else reject();
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function editAddress({}, { addressId, data }) {
  return new Promise((resolve, reject) => {
    UserAddresses.edit(addressId, data)
      .then(address => {
        resolve(address);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function activate({ commit }, id) {
  return new Promise((resolve, reject) => {
    Clients.activateUser({ clientId: id })
      .then(res => {
        if (res.data.status === 200) {
          commit(mutation.ACTIVATE_CLIENT, id);
        } else {
          reject(res);
        }
        resolve({ success: true });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function updateStatus({ commit }, { status, id }) {
  return new Promise((resolve, reject) => {
    Clients.changeUserStatus(id, status)
      .then(res => {
        if (res.data.status == 200) {
          resolve(res);
          commit(mutation.UPDATE_STATUS, { status, id });
        } else {
          reject(res);
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function updateUser({}, { id, data }) {
  return new Promise((resolve, reject) => {
    Clients.editUser(id, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        if (err.statusCode === Http.UNPROCESSABLE_ENTITY) {
          reject(new Error("Correo electrónico ya se encuentra registrado"));
        } else {
          reject(err);
        }
      });
  });
}

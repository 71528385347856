import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Wallets");

const wallets = Object.assign(
  {},
  {
    relation: "",
    payPending(clientId) {
      return loopback.post("/Wallets/payPending", { clientId });
    },
    listing({ filter = {}, where = {} }) {
      return loopback.get("/Wallets/listing", {
        params: { filter, where }
      });
    }
  },
  modelObject
);

export default wallets;

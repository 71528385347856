import { captureError } from "@/plugins/error-tracking";
import Services from "@/services/services";
import Http from "@zubut/common/src/constants/http";
import * as mutation from "./mutations-types";

const definePayError = err => {
  let error = null;
  if (err.statusCode === Http.BAD_REQUEST) {
    if (err.message == "Service has incidence")
      error = new Error("El servicio tiene incidencias");
    else {
      error = new Error("El servicio tiene status incorrecto o esta pagado");
    }
  }
  if (err.statusCode === Http.NOT_FOUND) {
    error = new Error("No se encontró el servicio");
  }
  if (err.statusCode === Http.SERVER_ERROR) {
    error = new Error("Error interno al realizar cobro");
  }
  if (err.statusCode == null) {
    error = new Error("Error desconocido, contacta al equipo técnico");
  }
  // fail safe
  if (error == null) error = err;
  return error;
};

export function payService({ commit }, data) {
  return new Promise((resolve, reject) => {
    Services.pay(data)
      .then(res => {
        if (res.data && res.data.status === Http.SUCCESS) {
          commit(mutation.DELETE_SERVICE, data.serviceId);
          resolve(res);
        } else {
          let error = definePayError(res);
          reject(error);
        }
      })
      .catch(err => {
        captureError(err);
        let error = definePayError(err);
        reject(error);
      });
  });
}

import _cloneDeep from "lodash/cloneDeep";
import * as mutation from "./mutations-types";

export default {
  [mutation.SET_PENDING_SERVICES](state, pendingServices) {
    state.pendingServices = _cloneDeep(pendingServices);
  },

  [mutation.DELETE_SERVICE](state, id) {
    const deleteService = state.pendingServices.filter(s => s.id !== id);
    state.pendingServices = deleteService;
  },

  [mutation.SET_FILTER_PENDING_CLIENT](state, pendingFilterClient) {
    state.pendingFilterClient = pendingFilterClient;
  },

  [mutation.SET_DATE](state, date) {
    state.date = date;
  }
};

import { isArray } from "lodash";
import { formatISO } from "@zubut/common/src/utils/time";
import { makeShortUUID } from "@/utils/strings";
import { getCombinedStatusName } from "@/utils/service-status";
import { formatMoney } from "@/utils/money";
import serviceRate from "@/mixins/serviceRate";

export function getEstimatedRate(service) {
  const rate = serviceRate.methods.calculateServiceRate(service);
  return formatMoney(rate);
}

export function listingFormat(data) {
  const format = service => ({
    ...service,
    destinationNo:
      service.destinationNo <= 0 ? "Sin destinos" : service.destinationNo,
    shortId: makeShortUUID(service.id),
    statusName: getCombinedStatusName(service),
    userName: service.user,
    estimatedRate: getEstimatedRate(
      Object.assign(service, { estimatedRate: service.cost })
    )
  });
  if (isArray(data)) {
    return data.map(format);
  } else {
    return format(data);
  }
}

export function accountFormat(data) {
  return data.map(service => ({
    id: service.id,
    createdAt: formatISO(val, "d 'de' LLLL 'de' Y H:mm"),
    info: service.driver ? service.driver : "No se asignó a un conductor",
    statusName: getCombinedStatusName(service),
    status: service.status,
    paymentStatus: service.paymentStatus,
    billingStatus: service.billingStatus,
    branch: service.user,
    archived: false,
    estimatedRate: formatMoney(service.cost)
  }));
}

import * as mutation from "./mutations-types";
import ServiceStatus from "@/constants/services/status";
import { captureError } from "@/plugins/error-tracking";
import Warehouses from "@/services/warehouses";
import Services from "@/services/services";
import Parcels from "@/services/parcels";

export function getWarehousesListing({ commit }, { filter, where }) {
  return new Promise((resolve, reject) => {
    Warehouses.listing({ filter, where })
      .then(({ data, meta }) => {
        commit(mutation.SET_WAREHOUSES, data);
        if (meta && meta.skip === 0) {
          commit(mutation.UPDATE_WAREHOUSES_TOTAL, meta.count);
        }
        resolve({ data, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getParcelsListing(
  { commit, state },
  { filter, where, warehouseId }
) {
  return new Promise((resolve, reject) => {
    if (where == null) {
      where = {};
      if (warehouseId) {
        where.warehouseId = where.warehouseId;
      } else {
        reject(new Error("Missing warehouseId"));
      }
      if (state.parcelsFilter.text)
        where.id = { like: `%${state.parcelsFilter.text}%` };
      if (state.parcelsFilter.status != null)
        where.status = state.parcelsFilter.status;
      if (state.parcelsFilter.deliveryMode != null)
        where.deliveryMode = state.parcelsFilter.deliveryMode;

      if (state.parcelsFilter.clientId != null)
        where.clientId = state.parcelsFilter.clientId;

      if (
        state.parcelsFilter.fromDate != null &&
        state.parcelsFilter.untilDate != null
      ) {
        where.range = {
          from: new Date(state.parcelsFilter.fromDate),
          until: new Date(state.parcelsFilter.untilDate)
        };
      } else if (state.parcelsFilter.fromDate != null) {
        where.deliveryDate = { gte: new Date(state.parcelsFilter.fromDate) };
      } else if (state.parcelsFilter.untilDate != null) {
        where.deliveryDate = { lte: new Date(state.parcelsFilter.untilDate) };
      }
    }

    if (filter == null) {
      filter = {};
      filter.limit = state.parcelsPagination.perPage;
    }

    filter.order = `deliveryDate ${state.parcelsFilter.dateOrder}`;

    Parcels.listing({ filter, where })
      .then(({ data, meta }) => {
        commit(mutation.SET_PARCELS, data);
        if (meta && meta.skip === 0) {
          commit(mutation.UPDATE_PARCELS_TOTAL, meta.count);
          commit(mutation.UPDATE_PARCELS_PAGINATION_CURRENT_PAGE, 1);
          commit(
            mutation.UPDATE_PARCELS_PAGINATION_PER_PAGE,
            filter?.limit ?? 10
          );
        }
        resolve({ data, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getCollectionServicesListing(
  { commit, state },
  { filter, where, warehouseId }
) {
  return new Promise((resolve, reject) => {
    if (where == null) {
      where = {};
      if (!warehouseId) reject(new Error("Missing warehouseId"));

      if (state.collectionsFilter.text)
        where.like = state.collectionsFilter.text;

      if (state.collectionsFilter.status != null)
        where.status = state.collectionsFilter.status;
    }

    if (filter == null) {
      filter = {};
      filter.limit = state.collectionsPagination.perPage;
    }

    filter.order = `createdAt ${state.collectionsFilter.dateOrder}`;

    Warehouses.collectionListing(warehouseId, { filter, where })
      .then(({ data, meta }) => {
        commit(mutation.SET_COLLECTIONS, data);
        if (meta && meta.skip === 0) {
          commit(mutation.UPDATE_COLLECTIONS_TOTAL, meta.count);
          commit(mutation.UPDATE_COLLECTIONS_PAGINATION_CURRENT_PAGE, 1);
          commit(
            mutation.UPDATE_COLLECTIONS_PAGINATION_PER_PAGE,
            filter?.limit ?? 10
          );
        }
        resolve({ data, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getDeliveryServicesListing(
  { commit, state },
  { filter, where, warehouseId }
) {
  return new Promise((resolve, reject) => {
    if (where == null) {
      where = {};
      if (!warehouseId) reject(new Error("Missing warehouseId"));

      if (state.deliveriesFilter.text) where.like = state.deliveriesFilter.text;

      if (state.deliveriesFilter.status != null)
        where.status = state.deliveriesFilter.status;
    }

    if (filter == null) {
      filter = {};
      filter.limit = state.deliveriesPagination.perPage;
    }

    filter.order = `createdAt ${state.deliveriesFilter.dateOrder}`;

    Warehouses.deliveriesListing(warehouseId, { filter, where })
      .then(({ data, meta }) => {
        commit(mutation.SET_DELIVERIES, data);
        if (meta && meta.skip === 0) {
          commit(mutation.UPDATE_DELIVERIES_TOTAL, meta.count);
          commit(mutation.UPDATE_DELIVERIES_PAGINATION_CURRENT_PAGE, 1);
          commit(
            mutation.UPDATE_DELIVERIES_PAGINATION_PER_PAGE,
            filter?.limit ?? 10
          );
        }
        resolve({ data, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function cancelService(_, data) {
  return new Promise((resolve, reject) => {
    Services.cancel(data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function finalizeService(_, id) {
  return new Promise((resolve, reject) => {
    Services.finalizeService(id, { status: ServiceStatus.NUM_FINISHED })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function assignService({ commit }, { serviceId, driverId }) {
  return new Promise((resolve, reject) => {
    Services.assignService(serviceId, driverId)
      .then(res => {
        commit(mutation.UPDATE_SERVICE, { id: serviceId, driverId });
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function reassignService({ commit }, { serviceId, driverId }) {
  return new Promise((resolve, reject) => {
    Services.reassignService(serviceId, driverId)
      .then(res => {
        commit(mutation.UPDATE_SERVICE, { id: serviceId, driverId });
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function handleIncomingServiceUpdate({ commit }) {
  commit(mutation.TOGGLE_UPDATE_INDICATOR, true);
}

/* eslint-disable */
import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Accounts");

let Accounts = Object.assign(
  {},
  {
    relation: "",

    users: Object.assign({}, { relation: "/users" }, modelObject),

    billingData: Object.assign({}, { relation: "/billingData" }, modelObject),

    addNewUser(data) {
      return loopback.post(`/Accounts/addNewUser`, { data });
    },

    edit(id, data) {
      return loopback.put(`/Accounts/${id}/edit`, data);
    },

    getCurrentId() {
      return JSON.parse(localStorage.getItem("loopback-token")).userId;
    },

    getAccounts() {
      return loopback.get(`/Accounts`);
    },

    getAccountUsers(data) {
      return loopback.get(`/Accounts/users`, data);
    },

    changeAccountStatus(id) {
      return loopback.post(`/Accounts/${id}/changeStatus`);
    },

    createAccount(data) {
      data.status = 1;
      data.createdAt = new Date();
      return loopback.post(`/Accounts`, data);
    },

    linkExistingUser(data) {
      return loopback.post(`/Accounts/linkExistingUser`, { data });
    }
  },
  modelObject
);

export default Accounts;

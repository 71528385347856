export const ADD_SERVICES = "addServices";
export const REMOVE_SERVICE = "removeService";
export const UPDATE_SERVICE = "updateService";
export const TOGGLE_UPDATE_INDICATOR = "toggleUpdateIndicator";

export const UPDATE_TOTAL_ITEMS = "updateTotalItems";

export const SET_FILTER_CLIENT = "setFilterClient";
export const SET_FILTER_ALERT = "setFilterAlert";
export const SET_FILTER_SORT_BY = "setFilterSortBy";
export const SET_FILTER_SORT_ORDER = "setFilterSortOrder";
export const SET_FILTER_VEHICLE_TYPE = "setFilterVehicleType";
export const SET_FILTER_STATUS = "setFilterStatus";
export const SET_FILTER_SEARCH = "setFilterSearch";

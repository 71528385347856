const moduleName = "Mensajeros";

const READ_DRIVERS = "Ver listado de mensajeros";
const READ_DRIVER_DETAIL = "Ver detalle de mensajero";
const READ_DRIVER_SERVICES = "Ver servicios del mensajero";
const CREATE_DRIVER = "Crear mensajero";
const EDIT_DRIVER_BASIC_INFO = "Editar información básica del usuario";
const EDIT_DRIVER_BILLING_DATA = "Editar información de facturación mensajero";
const EDIT_DRIVER_ADMIN_DATA = "Editar información admin del mensajero";
const MANAGE_VEHICLES =
  "Crear, editar, eliminar placas y vehículo del mensajero";
const RESET_PASSWORD = "Resetear contraseña";
const BLOCK_DRIVER = "Bloquear mensajero";

export default {
  moduleName,
  READ_DRIVERS,
  READ_DRIVER_DETAIL,
  READ_DRIVER_SERVICES,
  CREATE_DRIVER,
  EDIT_DRIVER_BASIC_INFO,
  EDIT_DRIVER_BILLING_DATA,
  EDIT_DRIVER_ADMIN_DATA,
  MANAGE_VEHICLES,
  RESET_PASSWORD,
  BLOCK_DRIVER
};

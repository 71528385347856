import TransactionReports from "@/services/transaction-report";
import { captureError } from "@/plugins/error-tracking";
import * as mutation from "./mutations-types";

export function getListing(
  { commit },
  { where, filter, addColumns, selectedFilter }
) {
  return new Promise((resolve, reject) => {
    TransactionReports.listing({
      where,
      filter,
      addColumns,
      selectedFilter
    })
      .then(({ data, meta }) => {
        commit(mutation.SET_LISTING, data);
        if (meta && meta.skip === 0) {
          commit(mutation.UPDATE_LISTING_TOTAL, meta.count);
        }
        resolve({ data, meta });
      })
      .catch(error => {
        captureError(error);
        reject(error);
      });
  });
}

import { captureError } from "@/plugins/error-tracking";
import Services from "@/services/services";
import { accountFormat, listingFormat } from "@/utils/format/service";
import cloneDeep from "lodash/cloneDeep";
import ServiceStatus from "@/constants/services/status";
import ServiceType from "@zubut/common/src/constants/services/type";
import * as mutation from "./mutations-types";

export function assignService(
  { state, commit },
  { serviceId, driverId, type, driver }
) {
  return new Promise((resolve, reject) => {
    Services.assignService(serviceId, driverId)
      .then(res => {
        let driverName = "";
        let status = ServiceStatus.NUM_ACTIVE;
        let statusName = ServiceStatus.ACTIVE;

        // Services with type RxH don't pass to active state
        if (type === ServiceType.NUM_RENT_PER_HOUR) {
          status = ServiceStatus.NUM_CREATED;
          statusName = ServiceStatus.CREATED;
        }

        if (driver) {
          driverName = driver.name;
        }

        const serviceIndex = state.services.findIndex(
          item => item.id === serviceId
        );

        if (serviceIndex > -1) {
          const service = cloneDeep(state.services[serviceIndex]);

          if (driverName) {
            service.driver = driverName;
            service.driverId = driverId;
          }

          service.status = status;
          service.statusName = statusName;
          commit(mutation.UPDATE_SERVICE, { index: serviceIndex, service });
        }

        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

/*
 * Update the driver when the service has a status other than completed
 */
export function reassignService(
  { state, commit },
  { serviceId, driverId, driver, type }
) {
  return new Promise((resolve, reject) => {
    Services.reassignService(serviceId, driverId)
      .then(res => {
        let driverName = "";
        const status = ServiceStatus.NUM_ACTIVE;
        const statusName = ServiceStatus.ACTIVE;

        if (driver) {
          driverName = driver.name;
        }

        const serviceIndex = state.services.findIndex(
          item => item.id === serviceId
        );

        if (serviceIndex > -1) {
          const service = cloneDeep(state.services[serviceIndex]);

          if (driverName) {
            service.driver = driverName;
            service.driverId = driverId;
          }

          // If the service is RxH, keep the current status
          if (type !== ServiceType.NUM_RENT_PER_HOUR) {
            service.status = status;
            service.statusName = statusName;
          }
          commit(mutation.UPDATE_SERVICE, { index: serviceIndex, service });
        }

        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

// Update the driver when the service is complete
// TODO: Check if it's safe delete this action
export function changeDriver(
  { state, commit },
  { serviceId, driverId, driver }
) {
  return new Promise((resolve, reject) => {
    Services.changeDriver(serviceId, driverId)
      .then(res => {
        let driverName = "";

        if (driver) {
          driverName = driver.name;
        }

        const serviceIndex = state.services.findIndex(
          item => item.id === serviceId
        );

        if (serviceIndex > -1) {
          const service = cloneDeep(state.services[serviceIndex]);

          if (driverName) {
            service.driver = driverName;
            service.driverId = driverId;
          }

          commit(mutation.UPDATE_SERVICE, { index: serviceIndex, service });
        }

        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

// Update the driver when the service is complete
export function reactivateService(
  { state, commit },
  { serviceId, driverId, driver }
) {
  return new Promise((resolve, reject) => {
    Services.reactivate(serviceId, driverId)
      .then(res => {
        const status = ServiceStatus.NUM_ACTIVE;
        const statusName = ServiceStatus.ACTIVE;

        let driverName = "";

        if (driver) {
          driverName = `${driver.name} ${driver.lastname}`;
        }

        const serviceIndex = state.services.findIndex(
          item => item.id === serviceId
        );

        if (serviceIndex > -1) {
          const service = cloneDeep(state.services[serviceIndex]);

          if (driverName) {
            service.driver = driverName;
            service.driverId = driverId;
            service.status = status;
            service.statusName = statusName;
          }

          commit(mutation.UPDATE_SERVICE, { index: serviceIndex, service });
        }

        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function fetchServices({ commit, state }, { filter, where }) {
  return new Promise((resolve, reject) => {
    commit(mutation.UPDATE_PREVIOUS_FILTER, state.filter.date);
    Services.listing({ where, filter })
      .then(({ data: services, meta }) => {
        if (meta && meta.skip === 0) {
          commit(mutation.UPDATE_TOTAL_SERVICES, meta.count);
        }
        services = listingFormat(services);
        commit(mutation.ADD_ALL_SERVICES, services);
        resolve({ services, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function servicesHistory({ commit, state }, { filter, where }) {
  return new Promise((resolve, reject) => {
    commit(mutation.UPDATE_PREVIOUS_FILTER, state.filter.date);
    Services.history({ where, filter })
      .then(({ data: services, meta }) => {
        if (meta && meta.skip === 0) {
          commit(mutation.UPDATE_TOTAL_SERVICES, meta.count);
        }
        services = listingFormat(services);
        commit(mutation.ADD_ALL_SERVICES, services);
        resolve({ services, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function archiveService({ state, commit }, serviceId) {
  return new Promise((resolve, reject) => {
    Services.changeArchivedStatus({ serviceId })
      .then((_, err) => {
        if (err) {
          captureError(err);
          reject(error);
        } else {
          // Archive success
          // Delete object from props
          const index = state.services.findIndex(s => s.id === serviceId);
          if (index >= 0) {
            commit(mutation.DELETE_SERVICE, index);
            commit(mutation.UPDATE_TOTAL_SERVICES, state.totalServices - 1);
          }
          resolve();
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function cancel({ commit, state }, data) {
  return new Promise((resolve, reject) => {
    Services.cancel(data)
      .then(res => {
        if (res && res.response) {
          const index = state.services.findIndex(_ => _.id === data.id);
          if (index !== -1) {
            const status = ServiceStatus.NUM_CLIENT_CANCELLED;
            commit(mutation.UPDATE_SERVICE_STATUS, { index, status });
          }
          resolve(res);
        } else {
          reject();
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function handleSocketServiceUpdate(
  { dispatch },
  { serviceId, serviceType }
) {
  switch (serviceType) {
    case ServiceType.NUM_SIMPLE:
    case ServiceType.NUM_MULTI_POINT:
    case ServiceType.NUM_DYNAMIC:
      dispatch(
        "serviceCenter/multipoint/handleIncomingServiceUpdate",
        {
          serviceId
        },
        { root: true }
      );
      break;
    case ServiceType.NUM_RENT_PER_HOUR:
      dispatch(
        "serviceCenter/reservations/handleIncomingServiceUpdate",
        {
          serviceId
        },
        { root: true }
      );
      break;
    case ServiceType.NUM_PACKAGE_DELIVERY:
      dispatch(
        "serviceCenter/route/handleIncomingServiceUpdate",
        {},
        { root: true }
      );
      break;
  }
}

export function fetchFromAccount(_, { accountId, filter }) {
  return new Promise((resolve, reject) => {
    Services.listing({
      filter,
      where: {
        accountId,
        status: { neq: ServiceStatus.NUM_REQUESTING },
        archived: false
      }
    })
      .then(({ data, meta }) => {
        const services = accountFormat(data);
        resolve({ services, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function fetchServiceDetail({ state }, serviceId) {
  return new Promise((resolve, reject) => {
    Services.getDetails(serviceId)
      .then(({ service }) => {
        resolve(service);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function patchServiceStatus(_, id) {
  return new Promise((resolve, reject) => {
    Services.finish(id)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function calculateServiceRate(
  _,
  { minutes, kms, destinations, vehicleType, clientId }
) {
  return new Promise((resolve, reject) => {
    Services.serviceRate({
      additional: {
        estimatedTime: minutes,
        km: kms
      },
      destinations,
      vehicleType,
      clientId
    })
      .then(response => {
        const { cityId, rate, taxedRate, taxedParameters, km, time } = response;
        resolve({
          minutes: time,
          kms: km,
          rate: rate,
          taxedRate: taxedRate,
          cityId,
          vehicleRates: taxedParameters
        });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function changeServiceStatus({ state, commit }, id) {
  return new Promise((resolve, reject) => {
    Services.finish(id)
      .then(res => {
        if (res.data.status === 200) {
          const index = state.services.findIndex(service => service.id === id);
          commit(mutation.UPDATE_SERVICE_STATUS, {
            index,
            status: ServiceStatus.NUM_FINISHED
          });
          resolve(id);
        } else {
          reject();
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getQuickNumber({}) {
  return new Promise((resolve, reject) => {
    Services.quickNumbers()
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function reviewService(_, { serviceId, approve }) {
  return new Promise((resolve, reject) => {
    Services.review(serviceId, { approve })
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        captureError(error);
        reject(error);
      });
  });
}

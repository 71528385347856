export const ADD_ALL_SERVICES = "setService";
export const ADD_MULTIPLE_SERVICES = "addMultipleServices";
export const ADD_SERVICE = "addService";
export const DELETE_SERVICE = "removeService";
export const UPDATE_DATE = "setDate";
export const UPDATE_FILTER = "updateFilter";
export const UPDATE_FILTER_CITY = "updateFilterCity";
export const UPDATE_FILTER_DATE = "updateFilterDate";
export const UPDATE_FILTER_HAS_STATISTICS_FILTERS =
  "updateFilterHasStatisticsFilters";
export const UPDATE_FILTER_SERVICE_TYPE = "updateFilterServiceType";
export const UPDATE_FILTER_STATUS = "updateFilterStatus";
export const UPDATE_FILTER_VEHICLE_TYPE = "updateFilterVehicleType";
export const UPDATE_FILTER_PAYMENT_METHOD = "updatePaymentMethodFilter";
export const RESET_FILTERS = "resetFilters";
export const UPDATE_SEARCH_TEXT = "updateSearchText";
export const UPDATE_PREVIOUS_FILTER = "updatePreviousFilter";
export const UPDATE_SERVICE = "updateService";
export const UPDATE_SERVICE_STATUS = "updateServiceStatus";
export const UPDATE_TOTAL_SERVICES = "updateTotalServices";

const moduleName = "Wallet";

const READ_WALLET = "Ver listado de wallet";
const EDIT_WALLET_AMOUNT = "Editar monto del wallet";
const ACTIVATE_OR_DEACTIVATE_WALLET = "Activar/desactivar wallet";

export default {
  moduleName,
  READ_WALLET,
  EDIT_WALLET_AMOUNT,
  ACTIVATE_OR_DEACTIVATE_WALLET
};

import * as mutation from "./mutations-types";

export default {
  [mutation.SET_LISTING](state, items) {
    state.listing = [...items];
  },

  [mutation.UPDATE_LISTING_FILTER_TEXT](state, filterText) {
    state.listingFilter.text = filterText;
  },

  [mutation.UPDATE_LISTING_FILTER_ACTIVITY](state, activity) {
    state.listingFilter.activity = activity;
  },

  [mutation.UPDATE_LISTING_FILTER_SEVERITY](state, severity) {
    state.listingFilter.severity = severity;
  },

  [mutation.UPDATE_LISTING_FILTER_ROLE](state, role) {
    state.listingFilter.role = role;
  },

  [mutation.UPDATE_LISTING_FILTER_ADMIN](state, adminId) {
    state.listingFilter.adminId = adminId;
  },

  [mutation.UPDATE_LISTING_FILTER_CLIENT](state, clientId) {
    state.listingFilter.clientId = clientId;
  },

  [mutation.UPDATE_LISTING_FILTER_DRIVER](state, driverId) {
    state.listingFilter.driverId = driverId;
  },

  [mutation.UPDATE_LISTING_FILTER_SORT_ORDER](state, sortOrder) {
    state.listingFilter.sortOrder = sortOrder;
  },

  [mutation.UPDATE_LISTING_FILTER_DATE](state, date) {
    state.listingFilter.date = date;
  },

  [mutation.UPDATE_LISTING_FILTER_DATE_FROM](state, dateFrom) {
    state.listingFilter.dateFrom = dateFrom;
  },

  [mutation.UPDATE_LISTING_FILTER_DATE_UNTIL](state, dateUntil) {
    state.listingFilter.dateUntil = dateUntil;
  },

  [mutation.UPDATE_LISTING_FILTER_SERVICE](state, service) {
    state.listingFilter.service = service;
  },

  [mutation.UPDATE_LISTING_TOTAL](state, total) {
    state.listingTotal = total;
  }
};

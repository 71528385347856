import * as mutation from "./mutations-types";

export default {
  [mutation.SET_ROUTE_SERVICES](state, payload) {
    state.routeServices = [...payload];
  },

  [mutation.CLIENT_FILTER_CHANGED](state, payload) {
    state.clientFilter = payload;
  },

  [mutation.VEHICLE_FILTER_CHANGED](state, payload) {
    state.vehicleFilter = payload;
  },

  [mutation.SEARCH_TEXT_CHANGED](state, payload) {
    state.searchText = payload;
  },

  [mutation.UPDATE_TOTAL_SERVICES_COUNT](state, payload) {
    state.totalServices = payload;
  },

  [mutation.TOGGLE_UPDATE_INDICATOR](state, hasUpdates) {
    state.hasUpdates = hasUpdates;
  }
};

import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("UserAddresses");

let UserAddresses = Object.assign(
  {},
  {
    relation: "",

    edit(addressId, data) {
      return loopback.patch(`/UserAddresses/${addressId}`, data);
    }
  },
  modelObject
);

export default UserAddresses;

import axios from "axios";
import router from "@/router";
import Vue from "vue";
import websockets from "@/websockets";

const Storage = window.localStorage;

/**
 * Add a token in the local storage
 * */
function exportTokenToLocalStorage(token) {
  if (Storage) {
    Storage.setItem("loopback-token", JSON.stringify(token));
  }
}

/**
 * Remove token from local storage
 */
function removeTokenFromLocalStorage() {
  if (Storage) {
    Storage.removeItem("loopback-token");
    Storage.clear();
  }
}

function addTokenFromLocalStorage(http) {
  const token = Storage && Storage.getItem("loopback-token");
  if (token) {
    http.setToken(JSON.parse(token), false);
  }
}

const http = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`
});

http.setToken = (token, save = true) => {
  http.token = token;
  http.num_errors = 0;
  http.defaults.headers.common.Authorization = token.id;
  if (save) {
    exportTokenToLocalStorage(token);
  }
};

http.removeToken = () => {
  delete http.defaults.headers.common.Authorization;
  removeTokenFromLocalStorage();
};

http.successCallback = response => {
  return response;
};

http.errorCallback = error => {
  return error;
};

const expiredToken = () => {
  setTimeout(() => {
    Vue.notify({
      group: "login",
      title: "Sesión Expirada",
      text: "Tu sesión ha expirado debido a inactividad",
      type: "warn",
      duration: 5000
    });
  }, 400);
  http.num_errors += 1;
  http.removeToken();
  websockets.closeSocket();
  router.push({ name: "login" });
};

const interceptResErrors = err => {
  if (http.num_errors === 0 && err.response && err.response.status === 401) {
    expiredToken();
  }
  return new Promise(function(resolve, reject) {
    try {
      // If the response.data is somehow returned as a Blob
      // accessing the error property as response.data will
      // result in unexpected behavior, so it is parsed here
      // and returned as the object the caller expects.
      if (err && err.response) {
        if (err.response.data instanceof Blob) {
          let reader = new FileReader();
          reader.addEventListener("loadend", function() {
            return reject(JSON.parse(reader.result).error);
          });
          reader.addEventListener("error", function() {
            reader.abort();
            return reject(err);
          });
          reader.readAsText(err.response.data);
        } else {
          err = Object.assign(new Error(), err.response.data.error);
          return reject(err);
        }
      }
    } catch (e) {
      console.log("Caught e: ", e);
      return reject(err);
    }
  });
};

const interceptResponse = res => {
  try {
    return res.data;
  } catch (e) {
    return res;
  }
};
http.interceptors.response.use(interceptResponse, interceptResErrors);

// Set storage Token in http if exists
addTokenFromLocalStorage(http);

const interceptReqErrors = err => Promise.reject(err);
const interceptRequest = config => {
  return config;
};
http.interceptors.request.use(interceptRequest, interceptReqErrors);

export default http;

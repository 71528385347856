export const SET_CLIENTS = "setClients";
export const SET_CLIENTS_FILTER = "setClientsFilter";
export const SET_CLIENTS_CHECKBOX_FILTER = "setClientsCheckboxFilter";

export const UPDATE_STATUS = "updateStatus";
export const ACTIVATE_CLIENT = "activateClient";

/* Client creation */
export const RESET_CLIENT_CREATION = "resetClientCreation";
export const SET_ADDRESS = "setAddress";
export const SET_ADDRESS_REFERENCES = "setAddressReferences";
export const SET_BUSINESS_LINE = "setBusinessLine";
export const SET_CLIENT_TYPE = "setClientType";
export const SET_COMPANY_NAME = "setCompanyName";
export const SET_CONTACT_NAME = "setContactName";
export const SET_PHONE = "setPhone";
export const SET_EMAIL = "setEmail";
export const SET_PASSWORD = "setPassword";
export const SET_PARENT_COMPANY = "setParentCompany";

export default {
  getLisintg(state) {
    return state.listing;
  },
  getListingTotal() {
    return state.listingTotal;
  },
  getListingFilterText(state) {
    return state.listingFilter.text;
  },
  getListingFilterActivity(state) {
    return state.listingFilter.activity;
  },
  getListingFilterSeverity(state) {
    return state.listingFilter.severity;
  },
  getListingFilterRole(state) {
    return state.listingFilter.role;
  },
  getListingFilterAdmin(state) {
    return state.listingFilter.adminId;
  },
  getListingFilterClient(state) {
    return state.listingFilter.clientId;
  },
  getListingFilterDriver(state) {
    return state.listingFilter.driverId;
  },
  getListingFilterSortOrder(state) {
    return state.listingFilter.sortOrder;
  },
  getListingFilterDate(state) {
    return state.listingFilter.date;
  },
  getListingFilterDateFrom(state) {
    return state.listingFilter.dateFrom;
  },
  getListingFilterDateUntil(state) {
    return state.listingFilter.dateUntil;
  },
  getListingFilterService(state) {
    return state.listingFilter.service;
  },
  getListingTotal(state) {
    return state.listingTotal;
  }
};

const KILOMETER = "kilometer";
const HOUR = "hour";
const PACKAGE = "package";
const BATCH = "batch";
const GUIDE_DELIVERY = "GuideDelivery";
const GUIDE_PICKUP = "GuidePickup";

const NUM_KILOMETER = 1;
const NUM_HOUR = 2;
const NUM_PACKAGE = 3;
const NUM_BATCH = 4;
const NUM_GUIDE_DELIVERY = 5;
const NUM_GUIDE_PICKUP = 6;

export default {
  KILOMETER,
  HOUR,
  PACKAGE,
  BATCH,
  GUIDE_DELIVERY,
  GUIDE_PICKUP,
  NUM_KILOMETER,
  NUM_HOUR,
  NUM_PACKAGE,
  NUM_BATCH,
  NUM_GUIDE_DELIVERY,
  NUM_GUIDE_PICKUP
};

import Vue from "vue";
import { captureError, clearUser, setUser } from "@/plugins/error-tracking";
import loopback from "@/services/loopback";
import Clients from "@/services/clients";
import Admins from "@/services/admins";
import ws from "@/websockets";
import auth from "@/app/authentication";
import * as mutation from "./mutations-types";
import { defineAbilities } from "@/plugins/casl";
import Access from "@/constants/access";

/**
 * Sync loopback token with current state
 */
export function syncToken({ commit }) {
  if (loopback.token && auth.getLoggedUserData() != null) {
    const user = auth.getLoggedUserData();

    if (user.role) {
      defineAbilities(user.role);
    }

    commit(mutation.UPDATE_ACCESS_TOKEN, loopback.token);
    commit(mutation.UPDATE_USER, user);
    ws.init();
  }
}

const hasAccessToView = route => {
  switch (route.name) {
    case "admins":
      return Vue.prototype.$ability.can(
        Access.admins.READ_ADMINS,
        Access.admins.moduleName
      );
    case "clients":
      return Vue.prototype.$ability.can(
        Access.clients.READ_CLIENTS,
        Access.clients.moduleName
      );
    case "drivers":
      return Vue.prototype.$ability.can(
        Access.drivers.READ_DRIVERS,
        Access.drivers.moduleName
      );
    case "services":
      return Vue.prototype.$ability.can(
        Access.services.READ_SERVICES,
        Access.services.moduleName
      );
    case "statistics":
      return Vue.prototype.$ability.can(
        Access.statistics.READ_STATISTICS,
        Access.statistics.moduleName
      );
    case "payments":
      return Vue.prototype.$ability.can(
        Access.charges.READ_SERVICES,
        Access.charges.moduleName
      );
    case "transactions":
      const hasAccess =
        Access.transactions.HAS_ACCESS_READ_TRANSACTIONS() ||
        Access.transactions.HAS_ACCESS_READ_DRIVER_TRANSACTIONS() ||
        Access.transactions.HAS_ACCESS_READ_CUSTOMER_TRANSACTIONS();
      return hasAccess;
    default:
      return true;
  }
};

export function syncRouter({ state, commit, dispatch }, myRouter) {
  dispatch("syncToken");

  // Reload user persmissions on page refresh
  const userId = state?.access_token?.userId;
  if (userId) {
    dispatch("loadAccount", userId);
  }

  try {
    myRouter.beforeEach((to, _, next) => {
      // Check if user has access to the route
      if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!state.access_token || auth.getLoggedUserData() == null) {
          next({ name: "login" });
        } else {
          if (hasAccessToView(to)) {
            commit("setCurrentView", to, { root: true });
            next();
            return;
          }
          next({
            name: "restricted-access",
            query: { route: to.path }
          });
        }
      } else {
        next();
      }
    });

    // Hide 404 page
    myRouter.beforeResolve((_, __, next) => {
      commit("setDisplayNotFound", false, { root: true });
      next();
    });
  } catch (err) {
    captureError(err);
  }
}

export function loadAccount({ commit }, clientId) {
  return new Promise((resolve, reject) => {
    Admins.findById({
      id: `${clientId}`,
      filter: {
        include: [
          {
            relation: "role"
          }
        ]
      }
    })
      .then(res => {
        if (res.role) {
          defineAbilities(res.role);
        }

        auth.setLoggedUser(res);
        setUser({ id: res.id, email: res.email });
        commit(mutation.UPDATE_USER, res);
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        loopback.removeToken();
        reject(err);
      });
  });
}

export function login({ commit, dispatch, state }, { email, password }) {
  return new Promise((resolve, reject) => {
    Admins.login({ email, password })
      .then(token => {
        commit(mutation.UPDATE_ACCESS_TOKEN, token);
        // Update Loopback Token
        if (state.access_token === null) {
          loopback.removeToken();
        } else {
          loopback.setToken(state.access_token);
        }
        dispatch("loadAccount", state.access_token.userId)
          .then(res => {
            ws.init();
            resolve(res);
          })
          .catch(err => reject(err));
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function logout({ commit, state }) {
  return new Promise((resolve, reject) => {
    Admins.logout({ accessToken: state.access_token })
      .then(() => {
        commit(mutation.CLEAN_ACCESS_TOKEN);
        loopback.removeToken();
        ws.closeSocket();
        clearUser();
        resolve();
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function logoutSync({ commit }) {
  return new Promise(resolve => {
    // Remove token
    commit(mutation.CLEAN_ACCESS_TOKEN);
    loopback.removeToken();
    clearUser();
    resolve();
  });
}

export function updateConnectState({ commit }, connect) {
  commit(mutation.UPDATE_CONNECTION_STATE, connect);
  if (connect) {
    commit(mutation.UPDATE_IS_RECONNECTING, false);
  }
}

export function reconnectServer({ commit, dispatch }) {
  console.log("reconnectServer");
  commit(mutation.UPDATE_IS_RECONNECTING, true);
  let counter = 0;

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const reconnectInterval = setInterval(() => {
        Admins.findById({ id: `${Clients.getCurrentId()}` })
          .then(() => {
            console.log("connection is back");
            commit(mutation.UPDATE_IS_RECONNECTING, false);
            clearInterval(reconnectInterval);
            resolve();
          })
          .catch(error => {
            captureError(error);
            console.log("looking for connection try", counter);
            counter++;
            if (counter > 5) {
              console.log("reconnect failed");
              clearInterval(reconnectInterval);
              dispatch("logoutSync").then(() => {
                commit(mutation.UPDATE_IS_RECONNECTING, false);
                reject();
              });
            }
          });
      }, 5000);
    }, 500);
  });
}

export function updateAbilities({ commit, getters }, { id, role }) {
  const isCurrentAdmin = id === getters.getSessionUserId;
  if (isCurrentAdmin && role) {
    defineAbilities(role);
    commit(mutation.UPDATE_USER_ROLE, role);
  }
}

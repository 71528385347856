import { captureError } from "@/plugins/error-tracking";
import Logs from "@/services/logs";
import * as mutation from "./mutations-types";

export function getListing({ commit }, { filter, where }) {
  return new Promise((resolve, reject) => {
    Logs.activityListing({ filter, where })
      .then(({ data, meta }) => {
        commit(mutation.SET_LISTING, data);
        if (meta && meta.skip === 0) {
          commit(mutation.UPDATE_LISTING_TOTAL, meta.count);
        }
        resolve({ data, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

import VehicleType from "@zubut/common/src/constants/vehicles/type";

const defaultDestination = {
  address: "",
  name: "",
  phone: "",
  information: "",
  id: null
};

const getDefaultState = () => ({
  destinations: [
    {
      index: 0,
      visible: true,
      data: defaultDestination
    },
    {
      index: 1,
      visible: false,
      data: defaultDestination
    }
  ],
  additionalOptions: {
    signature: false,
    driverReturn: false,
    restrictedItems: false,
    scheduled: null,
    visible: false
  },
  vehicleType: VehicleType.NUM_MOTORCYCLE,
  paymentMethod: "wallet",
  cardId: null,
  rates: {
    km: 0,
    minutes: 0,
    cost: 0,
    subtotal: 0,
    tax: 0,
    currency: ""
  },
  vehicleRates: {
    bikeKMCost: -1,
    carKMCost: -1,
    ecoKMCost: -1
  },
  driver: {
    id: null,
    name: "",
    visible: false
  },
  clientId: null,
  walletId: null,
  cardId: null,
  cityId: null
});

export default getDefaultState;

import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("ReservationBlocks");

const reservationBlocks = Object.assign(
  {},
  {
    relation: "",

    reviewAdditionalHoursRequest(id, data) {
      return loopback.post(
        `/ReservationBlocks/${id}/reviewAdditionalHoursRequest`,
        { approve: data }
      );
    }
  },
  modelObject
);

export default reservationBlocks;

export default {
  listingAdmins(state) {
    return state.listingAdmins;
  },

  adminsFilterOptions(state) {
    return [{ text: "Todos", value: null }].concat(
      state.adminsFilter.map(admin => ({
        value: admin.id,
        text: `${admin.name}`
      }))
    );
  }
};

export default {
  setLoggedUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  },

  getLoggedUserId() {
    return this.getLoggedUserData().id;
  },

  getLoggedUserData() {
    return JSON.parse(localStorage.getItem("user"));
  }
};

import Vue from "vue";
import { abilitiesPlugin } from "@casl/vue";
import { AbilityBuilder, Ability } from "@casl/ability";

Vue.use(abilitiesPlugin, new Ability());

export function defineAbilities(role) {
  const { can, cannot, rules } = new AbilityBuilder();

  Object.keys(role.access).forEach(key => {
    Object.keys(role.access[key]).forEach(subkey => {
      if (!role.access[key][subkey]) {
        cannot(subkey, key);
      } else {
        can(subkey, key);
      }
    });
  });

  Vue.prototype.$ability.update(rules);
}

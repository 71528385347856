const moduleName = "Administradores";

const READ_ADMINS = "Ver listado de administradores";
const READ_ADMIN_DETAIL = "Ver detalle de administrador";
const EDIT_ADMIN_ACCESS = "Editar roles y permisos";
const RESET_PASSWORD = "Resetear contraseña";
const BLOCK_ADMIN = "Bloquear administrador";

export default {
  moduleName,
  READ_ADMINS,
  READ_ADMIN_DETAIL,
  EDIT_ADMIN_ACCESS,
  RESET_PASSWORD,
  BLOCK_ADMIN
};

const defaultState = {
  services: [],
  filter: {
    status: null,
    date: 2,
    serviceType: null,
    vehicleType: null,
    cityId: null,
    hasStatisticsFilter: false,
    paymentMethod: null
  },
  searchText: "",
  previousFilter: null,
  date: { from: null, until: null },
  totalServices: 0,
  fetchReservation: 0
};

export default defaultState;

import UserStatus from "@/constants/clients/status";

export default {
  listingUsers(state) {
    return state.listingUsers;
  },

  linkable(state) {
    return state.users.filter(user => user.status === UserStatus.NUM_ACTIVE);
  }
};

import * as mutation from "./mutations-types";
import getDefaultState from "./state";
import _cloneDeep from "lodash/cloneDeep";

export default {
  [mutation.RESET](state) {
    Object.assign(state, getDefaultState());
  },

  // Destination creation
  [mutation.ADD_DESTINATION](state, destination) {
    state.destinations = [...state.destinations, { ...destination }];
  },

  [mutation.DELETE_DESTINATION](state, index) {
    state.destinations = state.destinations.filter((el, i) => i !== index);
  },

  [mutation.UPDATE_DESTINATION](state, destination) {
    state.destinations = state.destinations.map((item, i) => {
      if (destination.index === i) {
        return { ...destination };
      }
      return item;
    });
  },

  [mutation.UPDATE_DESTINATION_VISIBILITY](state, { index, visible }) {
    state.destinations = state.destinations.map(destination => {
      if (destination.index === index) {
        return { ...destination, visible };
      }
      return destination;
    });
  },

  [mutation.SET_ADDITIONAL_OPTIONS_DRIVER_RETURN](state, driverReturn) {
    state.additionalOptions.driverReturn = driverReturn;
  },

  [mutation.SET_ADDITIONAL_OPTIONS_SCHEDULED](state, scheduledDate) {
    state.additionalOptions.scheduled = scheduledDate;
  },

  [mutation.SET_ADDITIONAL_OPTIONS_SIGNATURE](state, signature) {
    state.additionalOptions.signature = signature;
  },

  [mutation.SET_ADDITIONAL_OPTIONS_RESTRICTED_ITEMS](state, restrictedItems) {
    state.additionalOptions.restrictedItems = restrictedItems;
  },

  [mutation.SET_ADDITIONAL_OPTIONS_VISIBLE](state, visible) {
    state.additionalOptions.visible = visible;
  },

  [mutation.SET_CARD_ID](state, cardId) {
    state.cardId = cardId;
  },

  [mutation.SET_CITY_ID](state, cityId) {
    state.cityId = cityId;
  },

  [mutation.SET_CLIENT_ID](state, clientId) {
    state.clientId = clientId;
  },

  [mutation.SET_DRIVER_ID](state, driverId) {
    state.driver.id = driverId;
  },

  [mutation.SET_DRIVER_NAME](state, name) {
    state.driver.name = name;
  },

  [mutation.SET_DRIVER_VISIBLE](state, visible) {
    state.driver.visible = visible;
  },

  [mutation.SET_PAYMENT_METHOD](state, paymentMethod) {
    state.paymentMethod = paymentMethod;
  },

  [mutation.SET_RATES](state, rates) {
    state.rates = rates;
  },

  [mutation.SET_RATES_COST](state, cost) {
    state.rates.cost = cost;
  },

  [mutation.SET_RATES_TAX](state, tax) {
    state.rates.tax = tax;
  },

  [mutation.SET_RATES_SUBTOTAL](state, subtotal) {
    state.rates.subtotal = subtotal;
  },

  [mutation.SET_RATES_KM](state, km) {
    state.rates.km = km;
  },

  [mutation.SET_RATES_MINUTES](state, minutes) {
    state.rates.minutes = minutes;
  },

  [mutation.SET_VEHICLE_RATES](state, vehicleRates) {
    state.vehicleRates = vehicleRates;
  },

  [mutation.SET_CURRENCY_RATES](state, currency) {
    state.rates.currency = currency;
  },

  [mutation.SET_VEHICLE_TYPE](state, vehicleType) {
    state.vehicleType = vehicleType;
  },

  [mutation.SET_WALLET_ID](state, walletId) {
    state.walletId = walletId;
  }
};

import { captureError } from "@/plugins/error-tracking";
import Logs from "@/services/logs";
import moment from "moment";
import * as mutation from "./mutations-types";
import ServiceType from "@zubut/common/src/constants/services/type";

export function getNotifications({ state, commit }, { filter, force }) {
  return new Promise((resolve, reject) => {
    const alreadyUpdated =
      !force &&
      state.lastUpdated !== null &&
      moment().diff(moment(state.lastUpdated).startOf("day"), "hours") < 24;
    if (alreadyUpdated) {
      resolve([]);
    } else {
      Logs.getLogs(filter)
        .then(response => {
          commit(mutation.SET_NOTIFICATIONS, response.data);
          commit(mutation.SET_LAST_UPDATED);
          resolve(response);
        })
        .catch(err => {
          captureError(err);
          reject(err);
        });
    }
  });
}

export function updateDriverPosition({ dispatch, rootState }, data) {
  const onlineDriver = rootState.request.onlineDrivers.find(
    driver => driver.id === data.driverId
  );
  // TODO delete this code if we don't have any issues
  // if (!onlineDriver) {
  //   dispatch("request/getOnlineDrivers", data.driverId, { root: true });
  //}
  dispatch("request/updateDriverPosition", data, { root: true });
}

export function newAdminNotification({ dispatch }, data) {
  if (data.serviceId) {
    dispatch(
      "services/handleSocketServiceUpdate",
      { serviceId: data.serviceId, serviceType: data.serviceType },
      { root: true }
    );
  }

  if (data.driverId && data.driverLogout === true) {
    dispatch("request/driverLogout", data.driverId, { root: true });
  }
  dispatch("notify", data);
}

export function notify({ commit }, data) {
  commit(mutation.ADD_NOTIFICATION, data);
}

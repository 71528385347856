import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faCalendarAlt,
  faCalendarDay,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCreditCard,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExternalLinkAlt,
  faFilter,
  faInfoCircle,
  faMap,
  faMapMarkerAlt,
  faMoon,
  faPencilAlt,
  faPlusCircle,
  faQuestionCircle,
  faSearch,
  faSlidersH,
  faSortDown,
  faStoreAlt,
  faSun,
  faTags,
  faTimes,
  faTimesCircle,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* Custom icons */
import {
  faAddNew,
  faApprove,
  faArrowDropDown,
  faBusiness,
  faClockCalendar,
  faDimensions,
  faDownload,
  faDriver,
  faExcel,
  faFavoriteAddress,
  faFullScreen,
  faHelmet,
  faHexagonBullet,
  faHistory,
  faIncidence,
  faInvoices,
  faLink,
  faLogout,
  faMoneySign,
  faNotification,
  faNotificationSettings,
  faPayments,
  faPlus,
  faPrint,
  faRefresh,
  faReload,
  faSignFile,
  faSort,
  faStatistics,
  faStopwatchAlt,
  faUnassigned,
  faUnpaid,
  faUser,
  faWallet,
  faWarehouse,
  faZubut,
  faZubutBike,
  faZubutCar,
  faZubutMotorcycle,
  faCopyDestination,
  faLogs
} from "./icons";

library.add(
  faAddNew,
  faApprove,
  faArrowDropDown,
  faBars,
  faBusiness,
  faCalendarAlt,
  faCalendarDay,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClockCalendar,
  faCreditCard,
  faDimensions,
  faDownload,
  faDriver,
  faDriver,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExcel,
  faExternalLinkAlt,
  faFavoriteAddress,
  faFilter,
  faFullScreen,
  faHelmet,
  faHexagonBullet,
  faHistory,
  faIncidence,
  faIncidence,
  faInfoCircle,
  faInvoices,
  faLink,
  faLogout,
  faLogout,
  faMap,
  faMapMarkerAlt,
  faMoneySign,
  faMoon,
  faNotification,
  faNotification,
  faNotificationSettings,
  faPayments,
  faPencilAlt,
  faPlus,
  faPlusCircle,
  faPrint,
  faQuestionCircle,
  faRefresh,
  faReload,
  faSearch,
  faSignFile,
  faSlidersH,
  faSort,
  faSortDown,
  faStatistics,
  faStopwatchAlt,
  faStoreAlt,
  faSun,
  faTags,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUnassigned,
  faUnassigned,
  faUnpaid,
  faUnpaid,
  faUser,
  faWallet,
  faWarehouse,
  faWarehouse,
  faZubut,
  faZubutBike,
  faZubutCar,
  faZubutMotorcycle,
  faSortDown,
  faQuestionCircle,
  faNotificationSettings,
  faRefresh,
  faReload,
  faExternalLinkAlt,
  faLink,
  faWarehouse,
  faDimensions,
  faCopyDestination,
  faLogs
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

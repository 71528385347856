import * as actions from "./actions";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    notifications: [],
    lastUpdated: null
  },
  actions,
  mutations,
  getters: {
    getNotificationsFilterdByText: state => text => {
      return state.notifications.filter(
        item => item.message.toLowerCase().indexOf(text) !== -1
      );
    }
  }
};

export default function(imageSource, width, height, radians) {
  return new Promise(resolve => {
    const image = new Image();
    image.src = imageSource;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.clearRect(0, 0, width, height);

      ctx.translate(width / 2, height / 2);
      ctx.rotate(radians);
      ctx.translate(-width / 2, -height / 2);
      ctx.drawImage(image, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });
}

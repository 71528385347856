import * as mutation from "./mutations-types";

export default {
  [mutation.SET_LISTING_USERS](state, users) {
    state.listingUsers = users;
  },
  [mutation.ADD_USER](state, user) {
    const users = [...state.users, user];
    state.users = users;
  },
  [mutation.UPDATE_USER_EMAIL](state, { id, email }) {
    const updatedUserEmail = state.users.map(user => {
      if (user.id === id) {
        user.email = email;
      }
      return user;
    });
    state.users = updatedUserEmail;
  }
};

const moduleName = "Centro de entregas";

const READ_EXPRESS_DELIVERIES = "Ver entregas Express";
const READ_RESERVATIONS = "Ver reservaciones RxH";
const READ_ROUTE_DELIVERIES = "Ver entrega por Rutas";
const READ_SAME_NEXT_DAY_DELIVERIES = "Ver entregas Same y Next Day";

export default {
  moduleName,
  READ_EXPRESS_DELIVERIES,
  READ_RESERVATIONS,
  READ_ROUTE_DELIVERIES,
  READ_SAME_NEXT_DAY_DELIVERIES
};

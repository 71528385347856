import Vue from "vue";
import Vuex from "vuex";
import DateConstants from "@/constants/filters/date";

import admin from "./modules/admin";
import auth from "./modules/auth";
import cities from "./modules/cities";
import clients from "./modules/clients";
import createService from "./modules/createService";
import drivers from "./modules/drivers";
import express from "./modules/express";
import incidences from "./modules/incidences";
import invoices from "./modules/invoices";
import logs from "./modules/logs";
import notification from "./modules/notification";
import payments from "./modules/payments";
import request from "./modules/request";
import serviceCenter from "./modules/serviceCenter";
import services from "./modules/services";
import transactions from "./modules/transactions";
import user from "./modules/user";
import utils from "./modules/utils";
import deliveries from "./modules/deliveries";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    packageVersion: process.env.VUE_APP_VERSION || "0.0.0",
    currentView: "",
    notificationDialog: {
      dateFilter: DateConstants.NUM_TODAY
    },
    listingCount: {
      admins: 0,
      drivers: 0,
      clients: 0
    },
    windowWidth: window.innerWidth,
    displayNotFound: false
  },

  modules: {
    admin,
    auth,
    cities,
    clients,
    createService,
    drivers,
    express,
    incidences,
    invoices,
    logs,
    notification,
    payments,
    request,
    serviceCenter,
    services,
    transactions,
    user,
    utils,
    deliveries
  },

  mutations: {
    setCurrentView(state, route) {
      state.currentView = route.name;
    },
    setNotificationDialog(state, notificationDialog) {
      state.notificationDialog = { ...notificationDialog };
    },
    setScreenListingCount(state, { screen, count }) {
      state.listingCount[screen] = count;
    },
    setWindowWidth(state, width) {
      state.windowWidth = width;
    },
    setDisplayNotFound(state, value) {
      state.displayNotFound = value;
    }
  },

  getters: {
    appVersion: state => {
      return state.packageVersion;
    }
  }
});

export default store;

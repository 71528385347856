import { captureError } from "@/plugins/error-tracking";
import Clients from "@/services/clients";
import * as mutation from "./mutations-types";

export function getListingUsers({ commit }, filter) {
  return new Promise((resolve, reject) => {
    Clients.listing(filter)
      .then(res => {
        commit(mutation.SET_LISTING_USERS, res.data);
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function createClient({ commit }, client) {
  return new Promise((resolve, reject) => {
    Clients.createClient(client)
      .then(res => {
        if (res.id) {
          commit(mutation.ADD_USER, res);
          resolve();
        } else {
          const err = new Error("Error desconocido");
          reject(err);
          captureError(err);
        }
      })
      .catch(err => {
        reject(err);
        captureError(err);
      });
  });
}

export function emailRecovery({}, email) {
  return new Promise((resolve, reject) => {
    Clients.emailRecovery({ email })
      .then(response => {
        if (response.data.status === 404) {
          reject(new Error("Usuario no localizado"));
        }
        if (response.data.status === 200) {
          resolve(response);
        }
        reject(new Error(respose));
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function setUserPasswordToDefault({}, id) {
  return new Promise((resolve, reject) => {
    Clients.setUserPasswordToDefault(id)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function updateAdditional({}, { clientId, data }) {
  return new Promise((resolve, reject) => {
    Clients.updateAdditional(clientId, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function updateBillingData({}, { clientId, data }) {
  return new Promise((resolve, reject) => {
    Clients.billingData
      .update({ id: clientId }, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function createBillingData({}, { clientId, data }) {
  return new Promise((resolve, reject) => {
    Clients.billingData
      .create({ id: clientId, data })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function dispatchBillingData(
  { dispatch },
  { clientId, data, billingDataClientId }
) {
  return new Promise((resolve, reject) => {
    if (data.id && clientId === billingDataClientId) {
      data.updatedAt = new Date();
      dispatch("updateBillingData", { clientId, data })
        .then(res => resolve(res))
        .catch(err => reject(err));
    } else {
      delete data.id;
      dispatch("createBillingData", { clientId, data })
        .then(res => resolve(res))
        .catch(err => reject(err));
    }
  });
}

import ServiceStatus from "@zubut/common/src/constants/services/status";

export default {
  getReservations(state) {
    return state.reservations;
  },
  getKanbanReservations(state) {
    const createdReservations = state.created;
    const assignedReservations = state.assigned;
    const onRouteReservations = state.active;
    const completedReservations = state.completed;
    return [
      {
        title: "Creado",
        items: createdReservations
      },
      {
        title: "Asignado",
        items: assignedReservations
      },
      {
        title: "En turno",
        items: onRouteReservations
      },
      {
        title: "Completado",
        items: completedReservations
      }
    ];
  },
  getFilters(state) {
    return state.filters;
  },
  getTotalItems(state) {
    return state.totalItems;
  },
  getHasUpdates(state) {
    return state.hasUpdates;
  },
  getLayoutType(state) {
    return state.layout;
  },
  getPage(state) {
    return state.filters.page;
  },
  hasCompletedReservations(state) {
    return state.completed.length > 0;
  }
};

import * as mutation from "./mutations-types";
import defaultState from "./state";
import Status from "@/constants/drivers/status";

export default {
  [mutation.RESET](state) {
    const s = defaultState;
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },

  [mutation.ADD_DRIVER](state, driver) {
    state.drivers = [...state.drivers, driver];
  },

  [mutation.ADD_DRIVERS](state, drivers) {
    state.drivers = drivers;
  },

  [mutation.DELETE_DRIVERS](state) {
    state.drivers = [];
  },

  [mutation.UPDATE_SELECTED_DRIVER](state, driver) {
    state.selected = { ...driver };
  },

  [mutation.ACTIVATE_DRIVER](state, id) {
    const activateDrivers = state.drivers.map(driver => {
      if (driver.id === id) {
        driver.status = Status.NUM_ACTIVE;
        driver.statusName = Status.get[Status.NUM_ACTIVE];
      }
      return driver;
    });
    state.drivers = activateDrivers;
  },

  [mutation.UPDATE_STATUS](state, { status, id }) {
    const updateStatusDriver = state.drivers.map(driver => {
      if (driver.id === id) {
        driver.status = status;
        driver.statusName = Status.get[status];
      }
      return driver;
    });
    state.drivers = updateStatusDriver;
  },

  [mutation.SET_DRIVERS_FILTER](state, drivers) {
    state.driversFilter = drivers;
  },

  [mutation.SET_LISTING](state, items) {
    state.listing = [...items];
  },

  [mutation.UPDATE_LISTING_FILTER_TEXT](state, filterText) {
    state.listingFilter.text = filterText;
  },

  [mutation.UPDATE_LISTING_FILTER_STATUS](state, status) {
    state.listingFilter.status = status;
  },

  [mutation.UPDATE_LISTING_FILTER_SORT_ORDER](state, sortOrder) {
    state.listingFilter.sortOrder = sortOrder;
  },

  [mutation.UPDATE_LISTING_FILTER_DATE](state, date) {
    state.listingFilter.date = date;
  },

  [mutation.UPDATE_LISTING_FILTER_DATE_FROM](state, dateFrom) {
    state.listingFilter.dateFrom = dateFrom;
  },

  [mutation.UPDATE_LISTING_FILTER_DATE_UNTIL](state, dateUntil) {
    state.listingFilter.dateUntil = dateUntil;
  },

  [mutation.UPDATE_LISTING_FILTER_SELECT_ALL](state, selectAll) {
    state.listingFilter.selectAll = selectAll;
  },

  [mutation.UPDATE_LISTING_FILTER_SELECTED](state, selected) {
    state.listingFilter.selected = [...selected];
  },

  [mutation.UPDATE_LISTING_TOTAL](state, total) {
    state.listingTotal = total;
  }
};

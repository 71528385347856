export const RESET = "RESET";

export const ADD_DESTINATION = "ADD_DESTINATION";
export const DELETE_DESTINATION = "DELETE_DESTINATION";
export const UPDATE_DESTINATION = "UPDATE_DESTINATION";
export const UPDATE_DESTINATION_VISIBILITY = "UPDATE_DESTINATION_VISIBILITY";

export const SET_ADDITIONAL_OPTIONS_SIGNATURE =
  "SET_ADDITIONAL_OPTIONS_SIGNATURE";
export const SET_ADDITIONAL_OPTIONS_RESTRICTED_ITEMS =
  "SET_ADDITIONAL_OPTIONS_RESTRICTED_ITEMS";
export const SET_ADDITIONAL_OPTIONS_DRIVER_RETURN =
  "SET_ADDITIONAL_OPTIONS_DRIVER_RETURN";
export const SET_ADDITIONAL_OPTIONS_SCHEDULED =
  "SET_ADDITIONAL_OPTIONS_SCHEDULED";
export const SET_ADDITIONAL_OPTIONS_VISIBLE = "SET_ADDITIONAL_OPTIONS_VISIBLE";

export const SET_CARD_ID = "SET_CARD_ID";

export const SET_CITY_ID = "SET_CITY_ID";

export const SET_CLIENT_ID = "SET_CLIENT_ID";

export const SET_DRIVER_ID = "SET_DRIVER_ID";
export const SET_DRIVER_NAME = "SET_DRIVER_NAME";
export const SET_DRIVER_VISIBLE = "SET_DRIVER_VISIBLE";

export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";

export const SET_RATES = "SET_RATES";
export const SET_RATES_COST = "SET_RATES_COST";
export const SET_RATES_TAX = "SET_RATES_TAX";
export const SET_RATES_SUBTOTAL = "SET_RATES_SUBTOTAL";
export const SET_RATES_KM = "SET_RATES_KM";
export const SET_RATES_MINUTES = "SET_RATES_MINUTES";
export const SET_VEHICLE_RATES = "SET_VEHICLE_RATES";
export const SET_CURRENCY_RATES = "SET_CURRENCY_RATES";

export const SET_VEHICLE_TYPE = "SET_VEHICLE_TYPE";

export const SET_ROUTE = "SET_ROUTE";

export const SET_WALLET_ID = "SET_WALLET_ID";

const moduleName = "Clientes";

const READ_CLIENTS = "Ver listado de clientes";
const READ_CLIENT_DETAIL = "Ver detalle de cliente";
const READ_CLIENT_SERVICES = "Ver servicios del cliente";
const EDIT_CLIENT_BILLING_DATA =
  "Editar información de facturación del cliente";
const EDIT_CLIENT_ADMIN_DATA = "Editar información admin del cliente";
const RESET_PASSWORD = "Resetear contraseña";
const BLOCK_CLIENT = "Bloquear cliente";

export default {
  moduleName,
  READ_CLIENTS,
  READ_CLIENT_DETAIL,
  READ_CLIENT_SERVICES,
  EDIT_CLIENT_BILLING_DATA,
  EDIT_CLIENT_ADMIN_DATA,
  RESET_PASSWORD,
  BLOCK_CLIENT
};

const moduleName = "transactions";

const DRIVERS_ACCOUNTING = "DRIVERS_ACCOUNTING";
const CUSTOMER_BALANCE = "CUSTOMER_BALANCE";
const TRANSACTION_HISTORY = "TRANSACTION_HISTORY";

export default {
  moduleName,
  DRIVERS_ACCOUNTING,
  CUSTOMER_BALANCE,
  TRANSACTION_HISTORY
};

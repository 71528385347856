export default {
  getLisintg(state) {
    return state.listing;
  },
  getListingTotal() {
    return state.listingTotal;
  },
  getListingFilterText(state) {
    return state.listingFilter.text;
  },
  getListingFilterStatus(state) {
    return state.listingFilter.status;
  },
  getListingFilterSortOrder(state) {
    return state.listingFilter.sortOrder;
  },
  getListingFilterDate(state) {
    return state.listingFilter.date;
  },
  getListingFilterDateFrom(state) {
    return state.listingFilter.dateFrom;
  },
  getListingFilterDateUntil(state) {
    return state.listingFilter.dateUntil;
  },
  getListingFilterSelectAll(state) {
    return state.listingFilter.selectAll;
  },
  getListingFilterSelected(state) {
    return state.listingFilter.selected;
  },
  getListingTotal(state) {
    return state.listingTotal;
  },
  getTotalSelectedDrivers(_, getters) {
    if (getters.getListingFilterSelectAll) {
      return getters.getListingTotal - getters.getListingFilterSelected.length;
    }
    return getters.getListingFilterSelected.length;
  },
  allDrivers(state) {
    return state.drivers;
  },
  activeDrivers(state) {
    return state.drivers.filter(driver => driver.status === 6);
  },
  getDriversByStatus(state) {
    return status =>
      state.drivers.filter(driver => driver.status === parseInt(status));
  },
  driversOptions(state) {
    return state.drivers.map(driver => ({
      value: driver.id,
      text: `${driver.name}`
    }));
  },
  driversFilterOptions(state) {
    return [{ text: "Todos", value: null }].concat(
      state.driversFilter.map(driver => ({
        value: driver.id,
        text: `${driver.name}`
      }))
    );
  }
};

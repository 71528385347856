const moduleName = "Cobros";

const READ_SERVICES = "Ver listado de servicios";
const CHARGE_SERVICE = "Cobrar servicio";
const CHARGE_ALL_SERVICES = "Cobrar todos los servicios";

export default {
  moduleName,
  READ_SERVICES,
  CHARGE_SERVICE,
  CHARGE_ALL_SERVICES
};

import * as _l from "lodash/lang";
import * as mutation from "./mutations-types";

export default {
  [mutation.SET_DEFAULT_ORIGIN](state, addressId) {
    state.default_origin = addressId;
  },
  [mutation.CLEAR_DEFAULT_ORIGIN](state) {
    state.default_origin = undefined;
  },
  [mutation.SET_ONLINE_DRIVERS](state, data) {
    state.onlineDrivers = data;
  },
  [mutation.SET_ACTIVE_SERVICES](state, data) {
    state.activeServices = data;
  },
  [mutation.SET_SERVICE_SELECTED](state, data) {
    state.serviceSelected = data;
  },
  [mutation.SET_SELECTED_DRIVER](state, data) {
    state.selectedDriver = data;
  },
  [mutation.SET_DRIVER_POSITION](state, data, index) {
    const drivers = [...state.onlineDrivers];
    drivers[index] = data;
    state.onlineDrivers = [...drivers];
  },
  [mutation.SET_WALLETS](state, data) {
    state.wallets = _l.cloneDeep(data);
  },
  [mutation.UPDATE_WALLET](state, { amount, pending, pendingAmount, id }) {
    const wallet = state.wallets.find(elem => elem.id === id);
    if (wallet) {
      wallet.amount = amount;
      if (pending != null) {
        wallet.pending = pending;
      }
      if (pendingAmount != null) {
        wallet.pendingAmount = pendingAmount;
      }

      wallet.isPending = wallet.pending !== "";
    }
  },
  [mutation.DELETE_ONLINE_DRIVER](state, driverId) {
    state.onlineDrivers = state.onlineDrivers.filter(
      driver => driver.id !== driverId
    );
  }
};

export default {
  services: state => {
    return state.routeServices;
  },

  clientSelected: state => {
    return state.clientFilter;
  },

  vehicleSelected: state => {
    return state.vehicleFilter;
  },

  searchText: state => {
    return state.searchText;
  },

  totalServicesCount: state => {
    return state.totalServices;
  },

  getHasUpdates(state) {
    return state.hasUpdates;
  }
};

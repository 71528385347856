const moduleName = "Entregas";

const READ_DELIVERIES = "Ver historial de entregas";
const READ_DELIVERY_DETAIL = "Ver detalle de entrega";

export default {
  moduleName,
  READ_DELIVERIES,
  READ_DELIVERY_DETAIL
};

/**
 * @typedef DriversState
 * @property {Array.<Transaction>}   listing                 - The items contained in the listing
 * @property {number}                listingTotal            - The total items that correspond to the filters applied
 * @property {object}                listingFilter           - The filters applied to the listing
 * @property {string}                listingFilter.search    - The text typed in the search input
 * @property {string}                listingFilter.sortBy    - The text typed in the search input
 * @property {"desc" | "asc"}        listingFilter.sortOrder - The order of the sort by property
 * @property {number}                listingFilter.status    - The selected driver status
 * @property {number}                listingFilter.date      - The selected type of date filter
 * @property {date}                  listingFilter.dateFrom  - The selected start of the custom date
 * @property {date}                  listingFilter.dateUntil - The selected end of the custom date
 * @property {boolean}               listingFilter.selectAll - Indicates if the user slected all drivers
 * @property {string[]}              listingFilter.selected  - The ids of the drivers selected
 */
/** @type {DriversState} */
const defaultState = {
  drivers: [],
  driversFilter: [],
  selected: {
    earningsResume: {},
    billingData: {
      address: {}
    },
    vehicles: []
  },
  listing: [],
  listingTotal: 0,
  listingFilter: {
    search: "",
    sortBy: "createdAt",
    sortOrder: "desc",
    text: null,
    status: null,
    date: null,
    dateFrom: null,
    dateUntil: null,
    selectAll: false,
    selected: []
  }
};

export default defaultState;

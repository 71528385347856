const moduleName = "Servicios";

const READ_SERVICES = "Ver listado de servicios";
const READ_SERVICE_DETAIL = "Ver detalle de servicio";
const ASSIGN_SERVICE = "Asignar servicio";
const REASSIGN_SERVICE = "Re-asignar servicio";
const FINISH_SERVICE = "Terminar servicio";
const CANCEL_SERVICE = "Cancelar servicio";
const ARCHIVE_SERVICE = "Archivar servicio";
const CREATE_SERVICE = "Crear servicios";

export default {
  moduleName,
  READ_SERVICES,
  READ_SERVICE_DETAIL,
  ASSIGN_SERVICE,
  REASSIGN_SERVICE,
  FINISH_SERVICE,
  CANCEL_SERVICE,
  ARCHIVE_SERVICE,
  CREATE_SERVICE
};

import * as mutation from "./mutations-types";

export default {
  /** @param state {import("./state").TransactionsState}} */
  [mutation.SET_LISTING](state, items) {
    state.listing = [...items];
  },

  /** @param state {import("./state").TransactionsState} */
  [mutation.UPDATE_LISTING_FILTER_SEARCH](state, text) {
    state.listingFilter.search = text;
  },

  /** @param state {import("./state").TransactionsState} */
  [mutation.UPDATE_LISTING_FILTER_CLIENT](state, clientId) {
    state.listingFilter.clientId = clientId;
  },

  /** @param state {import("./state").TransactionsState} */
  [mutation.UPDATE_LISTING_FILTER_COLUMNS](state, columns) {
    state.listingFilter.columns = columns;
  },

  /** @param state {import("./state").TransactionsState} */
  [mutation.UPDATE_LISTING_FILTER_DRIVER](state, driverId) {
    state.listingFilter.driverId = driverId;
  },

  /** @param state {import("./state").TransactionsState} */
  [mutation.UPDATE_LISTING_FILTER_SORT_ORDER](state, sortOrder) {
    state.listingFilter.sortOrder = sortOrder;
  },

  /** @param state {import("./state").TransactionsState} */
  [mutation.UPDATE_LISTING_FILTER_DATE](state, date) {
    state.listingFilter.date = date;
  },

  /** @param state {import("./state").TransactionsState} */
  [mutation.UPDATE_LISTING_FILTER_DATE_FROM](state, dateFrom) {
    state.listingFilter.dateFrom = dateFrom;
  },

  /** @param state {import("./state").TransactionsState} */
  [mutation.UPDATE_LISTING_FILTER_DATE_UNTIL](state, dateUntil) {
    state.listingFilter.dateUntil = dateUntil;
  },

  /** @param state {import("./state").TransactionsState} */
  [mutation.UPDATE_LISTING_FILTER_SERVICE](state, service) {
    state.listingFilter.service = service;
  },

  /** @param state {import("./state").TransactionsState} */
  [mutation.UPDATE_LISTING_FILTER_TYPE](state, type) {
    state.listingFilter.type = type;
  },

  /** @param state {import("./state").TransactionsState} */
  [mutation.UPDATE_LISTING_TOTAL](state, total) {
    state.listingTotal = total;
  }
};

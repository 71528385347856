import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("drivers");

let drivers = Object.assign(
  {},
  {
    relation: "",
    services: Object.assign({}, { relation: "/services" }, modelObject),

    billingData: Object.assign({}, { relation: "/billingData" }, modelObject),

    active() {
      return loopback.get(`/Drivers/active`);
    },

    createVechicle(driverId, data) {
      return loopback.post(`Drivers/${driverId}/vehicles`, data);
    },

    getCurrentId() {
      return JSON.parse(localStorage.getItem("loopback-token")).userId;
    },

    getVehicles(driverId) {
      return loopback.get(`/Drivers/${driverId}/vehicles`);
    },

    getOnlineDrivers() {
      return loopback.post(`/drivers/getOnlineDrivers`);
    },

    offlineDriversWithService() {
      return loopback.post(`/drivers/offlineDriversWithService`);
    },

    getRedisDriver(driverId) {
      return loopback.post(`/drivers/getRedisDriver`, { driverId });
    },

    changeDriverStatus(id, status) {
      return loopback.post(`/Drivers/${id}/changeStatus`, { status });
    },

    setDriverPasswordToDefault(data) {
      return loopback.post(`/Drivers/setDriverPasswordToDefault`, data);
    },

    updateAdminProperties(data) {
      return loopback.post(`/Drivers/updateAdminProperties`, data);
    },

    getTopDrivers(data) {
      return loopback.post(`/Drivers/getTopDrivers`, data);
    },

    register(data) {
      data.password = "zubut2018";
      return loopback.post(`/Drivers/register`, data);
    },

    finishDriverOnboarding(data) {
      data.password = "zubut2018";
      return loopback.post(`/Drivers/finishDriverOnboarding`, { data });
    },

    count(where) {
      return loopback.get(`/Drivers/count`, where);
    },

    listing(params) {
      return loopback.get("/Drivers/listing", { params });
    },

    nameListing(params) {
      return loopback.get("/Drivers/nameListing", { params });
    },

    editDriver(id, data) {
      return loopback.patch(`/Drivers/${id}`, data);
    },

    /**
     * Sends a push notification to the listed drivers
     * @param {object} data
     * @param {Filter} data.filter
     * @param {object} data.where is using loopback query syntax
     * @param {Notification} data.notification
     * @param {string} data.when Date to schedule notification
     */
    notify({ filter, where, notification, when }) {
      return loopback.post(`/Drivers/notify`, {
        filter,
        where,
        notification,
        when
      });
    }
  },
  modelObject
);

export default drivers;

/**
 * This object contains the parameters to filter data
 * @typedef {Object} Filter
 * @property {number} limit Number of elements to return
 * @property {number} skip Number of elements to skip
 * @property {string} order Order of elements to return
 */

/**
 * @typedef Notification
 * @type {Object}
 * @property {string} title
 * @property {string} message
 */

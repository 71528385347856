import * as mutation from "./mutations-types";

export default {
  // Warehouses mutations
  [mutation.SET_WAREHOUSES](state, warehouses) {
    state.warehouses = [...warehouses];
  },

  [mutation.UPDATE_WAREHOUSES_TOTAL](state, total) {
    state.warehousesTotal = total;
  },

  [mutation.UPDATE_WAREHOUSES_FILTER_CLIENT](state, clientId) {
    state.warehousesFilter.clientId = clientId;
  },

  // Parcels mutations
  [mutation.SET_PARCELS](state, parcels) {
    state.parcels = [...parcels];
  },

  [mutation.UPDATE_PARCELS_FILTER_DATE_ORDER](state, dateOrder) {
    state.parcelsFilter.dateOrder = dateOrder;
  },

  [mutation.UPDATE_PARCELS_FILTER_TEXT](state, text) {
    state.parcelsFilter.text = text;
  },

  [mutation.UPDATE_PARCELS_FILTER_STATUS](state, status) {
    state.parcelsFilter.status = status;
  },

  [mutation.UPDATE_PARCELS_FILTER_DELIVERY_MODE](state, deliveryMode) {
    state.parcelsFilter.deliveryMode = deliveryMode;
  },

  [mutation.UPDATE_PARCELS_FILTER_FROM](state, from) {
    state.parcelsFilter.fromDate = from;
  },

  [mutation.UPDATE_PARCELS_FILTER_UNTIL](state, until) {
    state.parcelsFilter.untilDate = until;
  },

  [mutation.UPDATE_PARCELS_FILTER_CLIENT](state, clientId) {
    state.parcelsFilter.clientId = clientId;
  },

  [mutation.UPDATE_PARCELS_TOTAL](state, total) {
    state.parcelsTotal = total;
  },

  [mutation.CLEAR_PARCELS_FILTERS](state) {
    state.parcelsFilter.text = null;
    state.parcelsFilter.status = null;
    state.parcelsFilter.deliveryMode = null;
    state.parcelsFilter.fromDate = null;
    state.parcelsFilter.untilDate = null;
    state.parcelsFilter.clientId = [];
  },

  [mutation.UPDATE_PARCEL_STATUS](state, params) {
    state.parcels[params.index].status = params.val;
  },

  // Parcels Pagination
  [mutation.UPDATE_PARCELS_PAGINATION_PER_PAGE](state, perPage) {
    state.parcelsPagination.perPage = perPage;
  },

  [mutation.UPDATE_PARCELS_PAGINATION_CURRENT_PAGE](state, currentPage) {
    state.parcelsPagination.currentPage = currentPage;
  },

  // Collection Services mutations
  [mutation.SET_COLLECTIONS](state, collections) {
    state.collections = [...collections];
  },

  [mutation.UPDATE_COLLECTIONS_FILTER_DATE_ORDER](state, dateOrder) {
    state.collectionsFilter.dateOrder = dateOrder;
  },

  [mutation.UPDATE_COLLECTIONS_FILTER_TEXT](state, text) {
    state.collectionsFilter.text = text;
  },

  [mutation.UPDATE_COLLECTIONS_FILTER_STATUS](state, status) {
    state.collectionsFilter.status = status;
  },

  [mutation.UPDATE_COLLECTIONS_TOTAL](state, total) {
    state.collectionsTotal = total;
  },

  [mutation.CLEAR_COLLECTIONS_FILTERS](state) {
    state.collectionsFilter.text = null;
    state.collectionsFilter.status = null;
  },

  // Collection Services Pagination
  [mutation.UPDATE_COLLECTIONS_PAGINATION_PER_PAGE](state, perPage) {
    state.collectionsPagination.perPage = perPage;
  },

  [mutation.UPDATE_COLLECTIONS_PAGINATION_CURRENT_PAGE](state, currentPage) {
    state.collectionsPagination.currentPage = currentPage;
  },

  [mutation.UPDATE_SERVICE](state, { id, driverId }) {
    state.collections = state.collections.map(serv => {
      if (serv.id === id) {
        return { ...serv, driverId };
      }
      return { ...serv };
    });

    state.deliveries = state.deliveries.map(serv => {
      if (serv.id === id) {
        return { ...serv, driverId };
      }
      return { ...serv };
    });
  },

  [mutation.TOGGLE_UPDATE_INDICATOR](state, hasUpdates) {
    state.hasUpdates = hasUpdates;
  },

  // Delivery Services mutations
  [mutation.SET_DELIVERIES](state, deliveries) {
    state.deliveries = [...deliveries];
  },

  [mutation.UPDATE_DELIVERIES_FILTER_DATE_ORDER](state, dateOrder) {
    state.deliveriesFilter.dateOrder = dateOrder;
  },

  [mutation.UPDATE_DELIVERIES_FILTER_TEXT](state, text) {
    state.deliveriesFilter.text = text;
  },

  [mutation.UPDATE_DELIVERIES_FILTER_STATUS](state, status) {
    state.deliveriesFilter.status = status;
  },

  [mutation.UPDATE_DELIVERIES_TOTAL](state, total) {
    state.deliveriesTotal = total;
  },

  [mutation.CLEAR_DELIVERIES_FILTERS](state) {
    state.deliveriesFilter.text = null;
    state.deliveriesFilter.status = null;
  },

  // Delivery Services Pagination
  [mutation.UPDATE_DELIVERIES_PAGINATION_PER_PAGE](state, perPage) {
    state.deliveriesPagination.perPage = perPage;
  },

  [mutation.UPDATE_DELIVERIES_PAGINATION_CURRENT_PAGE](state, currentPage) {
    state.deliveriesPagination.currentPage = currentPage;
  }
};

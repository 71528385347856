import moment from "moment";
import * as mutation from "./mutations-types";

export default {
  [mutation.SET_NOTIFICATIONS](state, data) {
    const notifications = data.map(item => {
      item.parsedDate = moment(item.date).format("h:mm a");
      return item;
    });
    state.notifications = notifications;
  },

  [mutation.ADD_NOTIFICATION](state, data) {
    data.parsedDate = moment(data.date).format("h:mm a");
    state.notifications = [data].concat(state.notifications);
  },

  [mutation.SET_LAST_UPDATED](state) {
    state.lastUpdated = moment().format();
  }
};

import { captureError } from "@/plugins/error-tracking";
import Drivers from "@/services/drivers";
import Vehicles from "@/services/vehicles";
import DriverStatus from "@/constants/drivers/status";
import EarningsServiceType from "@/constants/earnings/serviceType";
import Http from "@zubut/common/src/constants/http";
import { dateRange, formatToISO } from "@zubut/common/src/utils/time";
import DateFilters from "@zubut/common/src/constants/filters/date";
import { endOfDay, parseISO } from "date-fns";
import * as mutation from "./mutations-types";

export function setSelectedDriver({ commit }, driverId) {
  return new Promise((resolve, reject) => {
    const filter = {
      include: ["billingData", "ratings", "earnings", "vehicles"]
    };
    Drivers.findById({ id: driverId, filter })
      .then(driver => {
        if (driver.image_sm) {
          driver.profileImg = `https://zubut.com/images/profile/${driver.image_sm}`;
        }

        if (driver.ratings.length > 0) {
          let total = 0;
          driver.ratings.forEach(item => {
            if (item.rate === 1) {
              total += item.rate;
            }
          });
          driver.totalRate = (total / (driver.ratings.length * 100)).toFixed(0);
        } else {
          driver.totalRate = 100;
        }

        if (!driver.billingData) {
          // driver.billingData = {};
        }

        if (driver.earnings) {
          driver.earningsResume = {};
          driver.earnings.forEach(earning => {
            switch (earning.serviceType) {
              case EarningsServiceType.NUM_KILOMETER:
                driver.earningsResume.kmEarnings = earning.percentage * 100;
                break;
              case EarningsServiceType.NUM_HOUR:
                driver.earningsResume.hourEarnings = earning.percentage * 100;
                break;
              case EarningsServiceType.NUM_PACKAGE:
                driver.earningsResume.packageEarnings =
                  earning.percentage * 100;
                break;
              case EarningsServiceType.NUM_BATCH:
                driver.earningsResume.batchEarnings = earning.percentage * 100;
              default:
                break;
            }
          });
        }
        commit(mutation.UPDATE_SELECTED_DRIVER, driver);
        resolve(driver);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getAllDrivers({ commit }) {
  const filter = {
    arrayToSearch: ["email", "name", "_id"],
    searchedId: "driversId",
    order: "lastLogin DESC",
    where: {
      status: { gte: -1 }
    }
  };
  return new Promise((resolve, reject) => {
    Drivers.find({ filter: { ...filter } })
      .then(drivers => {
        commit(mutation.DELETE_DRIVERS);
        commit(mutation.ADD_DRIVERS, drivers);
        resolve(drivers);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getListing({ commit }, { filter, where }) {
  return new Promise((resolve, reject) => {
    Drivers.listing({ filter, where })
      .then(({ data, meta }) => {
        commit(mutation.SET_LISTING, data);
        if (meta && meta.skip === 0) {
          commit(mutation.UPDATE_LISTING_TOTAL, meta.count);
        }
        resolve({ data, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getListingDrivers({ commit }, like) {
  const filter = {
    order: "lastLogin DESC",
    limit: 10
  };
  const where = {
    status: 6,
    like
  };
  return new Promise((resolve, reject) => {
    Drivers.listing({ filter, where })
      .then(response => {
        const drivers = response.data;
        if (drivers.length > 0) {
          commit(mutation.DELETE_DRIVERS);
        }
        commit(mutation.ADD_DRIVERS, drivers);
        resolve(drivers);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function registerDriver({ commit }, data) {
  return new Promise((resolve, reject) => {
    Drivers.register(data)
      .then(res => {
        data.id = res.id;
        data.status = 0;
        data.statusName = DriverStatus.get[data.status];
        commit(mutation.ADD_DRIVER, data);
        resolve(res);
      })
      .catch(err => {
        let message = "Hubo un problema al crear al mensajero";

        if (err.statusCode === 400) {
          message = `Ya existe un mensajero con el correo: ${data.email}`;
        }

        captureError(err);
        reject({ message });
      });
  });
}

export function getRedidDriver(_, { driverId }) {
  return new Promise((resolve, reject) => {
    // FIXME: return driver null
    Drivers.getRedisDriver(driverId)
      .then(({ driver: res }) => {
        resolve(res.driver);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function setUserPasswordToDefault({}, id) {
  return new Promise((resolve, reject) => {
    Drivers.setDriverPasswordToDefault({
      driverId: id
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function updateStatus({ commit }, { status, id }) {
  return new Promise((resolve, reject) => {
    Drivers.changeDriverStatus(id, status)
      .then(res => {
        if (res.data.status == 200) {
          resolve(res);
          commit(mutation.UPDATE_STATUS, { status, id });
        } else {
          reject(res);
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function activate({ commit }, id) {
  return new Promise((resolve, reject) => {
    Drivers.finishDriverOnboarding({ driverId: id })
      .then(res => {
        if (res.data.status === 200) {
          commit(mutation.ACTIVATE_DRIVER, id);
        } else {
          reject(res);
        }
        resolve({ success: true });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getVehicles({ dispatch }, id) {
  return new Promise((resolve, reject) => {
    Drivers.getVehicles(id)
      .then(res => {
        if (res.length === 0) {
          reject(new Error("El usuario no tiene vehiculos"));
        }
        let current = undefined;
        if (res.length === 1) {
          current = res[0].id;
          resolve({ vehicles: res, current });
        } else {
          dispatch("getRedidDriver", id)
            .then(redisDriver => {
              current = redisDriver?.vehicleId;
              resolve({ vehicles: res, current });
            })
            .catch(err => {
              reject(err);
            });
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function createVehicle({}, { driverId, data }) {
  return new Promise((resolve, reject) => {
    Drivers.createVechicle(driverId, data)
      .then(vehicle => {
        if (vehicle.id) {
          resolve(vehicle);
        } else {
          reject(new Error("Error on create vehicle"));
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function editVehicle({}, { vehicleId, data }) {
  return new Promise((resolve, reject) => {
    Vehicles.edit(vehicleId, data)
      .then(vehicle => {
        if (vehicle.id) {
          resolve(vehicle);
        } else {
          reject(new Error("Error on edit vehicle"));
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function deleteVehicle({}, vehicleId) {
  return new Promise((resolve, reject) => {
    Vehicles.delete(vehicleId)
      .then(() => {
        resolve();
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function updateBillingData({}, { clientId, data }) {
  return new Promise((resolve, reject) => {
    Drivers.billingData
      .update({ id: clientId }, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function createBillingData({}, { clientId, data }) {
  return new Promise((resolve, reject) => {
    Drivers.billingData
      .create({ id: clientId, data })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function dispatchBillingData({ dispatch }, { clientId, data }) {
  return new Promise((resolve, reject) => {
    if (data.id) {
      data.updatedAt = new Date();
      dispatch("updateBillingData", { clientId, data })
        .then(res => resolve(res))
        .catch(err => reject(err));
    } else {
      delete data.id;
      dispatch("createBillingData", { clientId, data })
        .then(res => resolve(res))
        .catch(err => reject(err));
    }
  });
}

export function updateUser({}, { id, data }) {
  return new Promise((resolve, reject) => {
    Drivers.editDriver(id, data)
      .then(res => {
        if (res.email) {
          // TODO: You can update driver data here
        }
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        if (err.statusCode === Http.UNPROCESSABLE_ENTITY) {
          reject(new Error("Correo electrónico ya se encuentra registrado"));
        } else {
          reject(err);
        }
      });
  });
}

export function listing({}, filter) {
  return new Promise((resolve, reject) => {
    Drivers.listing(filter)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getDriversFilter({ state, commit }, where) {
  return new Promise((resolve, reject) => {
    Drivers.nameListing({
      filter: { skip: 0, limit: 5, order: "lastLogin DESC" },
      where
    })
      .then(drivers => {
        commit(mutation.SET_DRIVERS_FILTER, drivers.data);
        resolve(drivers);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

const buildNotificationsWhere = getters => {
  const where = { like: getters.getListingFilterText };
  const date = {
    from: getters.getListingFilterDateFrom,
    until: getters.getListingFilterDateUntil
  };
  if (getters.getListingFilterStatus !== null) {
    where.status = getters.getListingFilterStatus;
  }

  if (getters.getListingFilterDate !== DateFilters.NUM_CUSTOM) {
    const newDate = dateRange(getters.getListingFilterDate);
    if (newDate.from != null && newDate.until != null) {
      newDate.from = formatToISO(newDate.from);
      newDate.until = formatToISO(newDate.until);
    }
    date.from = newDate.from;
    date.until = newDate.until;
  }

  if (date.from != null && date.until != null) {
    const from = date.from instanceof Date ? formatToISO(date.from) : date.from;
    const until =
      date.until instanceof Date ? date.until : parseISO(date.until);
    where.range = {
      from,
      until:
        getters.getListingFilterDate === DateFilters.NUM_CUSTOM
          ? formatToISO(endOfDay(until))
          : formatToISO(until)
    };
  }

  return where;
};

/**
 * Sends a push notification to the listed drivers
 * @param {object} store store
 * @param {import("./state").DriversState} store.state
 * @param {object} options actions options
 * @param {Notification} options.notification notification
 * @param {object} driver driver to send notification to
 * */
export function sendMessages(
  { commit, getters },
  { notification, when, driver }
) {
  return new Promise((resolve, reject) => {
    const request = {
      notification,
      when,
      filter: {
        limit: getters.getTotalSelectedDrivers,
        skip: 0
      }
    };
    if (driver) {
      request.where = {
        id: driver.id
      };
      request.filter.limit = 1;
    } else if (getters.getListingFilterSelectAll) {
      request.where = buildNotificationsWhere(getters);
      request.where.id = {
        nin: getters.getListingFilterSelected
      };
    } else {
      request.where = {
        id: { inq: getters.getListingFilterSelected }
      };
      request.filter.limit = 1;
    }

    Drivers.notify(request)
      .then(() => {
        commit(mutation.UPDATE_LISTING_FILTER_SELECT_ALL, false);
        commit(mutation.UPDATE_LISTING_FILTER_SELECTED, []);
        resolve();
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

/**
 * @typedef Notification
 * @type {Object}
 * @property {string} title
 * @property {string} message
 */

/* Globally used components */
import Vue from "vue";
import LoadingSpinner from "@zubut/common/src/components/LoadingSpinner";
import ZButton from "@zubut/common/src/components/ZButton";
import QuickMessage from "@zubut/common/src/components/QuickMessage";
import ZIcon from "@zubut/common/src/components/ZIcon";
import ZInput from "@zubut/common/src/components/ZInput";

Vue.component("loading-spinner", LoadingSpinner);
Vue.component("z-button", ZButton);
Vue.component("z-icon", ZIcon);
Vue.component("z-input", ZInput);
Vue.component("quick-message", QuickMessage);

import currency from "currency.js";
import { roundTo } from "./numbers";

const moneyFormat = value =>
  currency(value, { symbol: "$", separator: ",", decimal: "." });

export function formatMoney(amount) {
  try {
    return moneyFormat(amount).format();
  } catch (e) {
    console.log(e);
  }
}

export function taxSubtotal(subtotal) {
  if (subtotal == null) return null;
  return roundTo(subtotal * 1.16, 2);
}

import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Services");

const services = Object.assign(
  {},
  {
    relation: "",

    assignService(id, driverId) {
      return loopback.post(`/Services/${id}/assign`, { driverId });
    },

    calculateRate(data) {
      return loopback.post("/Services/calculateRate", data);
    },

    cancel(data) {
      return loopback.post(`/Services/${data.id}/cancel`, data);
    },

    changeArchivedStatus({ serviceId }) {
      return loopback.post("/Services/changeArchivedStatus", { serviceId });
    },

    changeDriver(id, driverId) {
      return loopback.post(`/Services/${id}/changeDriver`, { driverId });
    },

    driverRoute(serviceId) {
      return loopback.get(`/Services/${serviceId}/driverRoute`);
    },

    finish(id) {
      return loopback.post(`/Services/${id}/finish`);
    },

    getDetails(id) {
      return loopback.get(`/Services/${id}/details`);
    },

    getNonPaid(data) {
      return loopback.post("/Services/getNonPaid", data);
    },

    listing({ filter = {}, where = {} }) {
      filter.order = filter.order || "createdAt DESC";
      return loopback.get("/Services/listing", {
        params: { filter, where }
      });
    },

    history({ filter = {}, where = {} }) {
      filter.order = filter.order || "createdAt DESC";
      return loopback.get("/Services/history", {
        params: { filter, where }
      });
    },

    packageService(data) {
      return loopback.post("/Services/packageService", data);
    },

    paidServices(serviceArray) {
      return loopback.post("/Services/paidServices", {
        services: serviceArray
      });
    },

    pay({ id, payment }) {
      return loopback.post(`/Services/${id}/pay`, payment);
    },

    quickNumbers() {
      return loopback.get("/Services/quickNumbers");
    },

    reassignService(id, driverId) {
      return loopback.post(`/Services/${id}/reassign`, { driverId });
    },

    reactivate(id, driverId) {
      return loopback.post(`/Services/${id}/reactivate`, { driverId });
    },

    review(id, data) {
      return loopback.post(`/Services/${id}/review`, data);
    },

    serviceEstimation(data) {
      return loopback.post("/Services/serviceEstimation", data);
    },

    serviceRate(destinations, additional, vehicleType) {
      return loopback.post(
        "/Services/serviceRate",
        destinations,
        additional,
        vehicleType
      );
    },

    validateIncidence(data) {
      return loopback.post("/Services/validateIncidence", data);
    },

    destinationArrived(data) {
      return loopback.post("/Services/destinationArrived", data);
    },

    destinationCompleted(data) {
      return loopback.post("/Services/destinationCompleted", data);
    },

    finalizeService(serviceId, data) {
      return loopback.patch(`/Services/${serviceId}`, data);
    }
  },
  modelObject
);

export default services;

const defaultState = {
  warehouses: [],
  warehousesTotal: 0,
  warehousesFilter: {
    clientId: []
  },
  parcels: [],
  parcelsFilter: {
    text: null,
    status: null,
    deliveryMode: null,
    fromDate: null,
    untilDate: null,
    clientId: [],
    dateOrder: "desc"
  },
  parcelsPagination: {
    perPage: 10,
    currentPage: 1
  },
  parcelsTotal: 0,
  collections: [],
  collectionsFilter: {
    text: null,
    status: null,
    dateOrder: "desc"
  },
  collectionsPagination: {
    perPage: 10,
    currentPage: 1
  },
  collectionsTotal: 0,
  deliveries: [],
  deliveriesFilter: {
    text: null,
    status: null,
    dateOrder: "desc"
  },
  deliveriesPagination: {
    perPage: 10,
    currentPage: 1
  },
  deliveriesTotal: 0,
  hasUpdates: false
};

export default defaultState;

import Accounts from "@/services/accounts";
import Invoices from "@/services/invoices";
import Clients from "@/services/clients";
import { formatMoney } from "@/utils/money";
import { captureError } from "@/plugins/error-tracking";
import * as mutation from "./mutations-types";

export function fetchUsers({ commit, state }) {
  return new Promise((resolve, reject) => {
    if (state.billingUsers.length === 0) {
      Clients.getAll({
        filter: {
          include: [
            "billingData",
            { relation: "account", scope: { include: ["billingData"] } }
          ]
        }
      })
        .then(res => {
          const users = res.map(user => {
            if (!user.name) {
              user.name = "- Sin nombre";
            }

            if (
              user.billingData == null &&
              user.account &&
              user.account.length &&
              user.account[0].billingData
            ) {
              user.billingData = user.account[0].billingData;
              user.hasBillingData = false;
              user.hasAccountBillingData = true;
            } else {
              user.hasBillingData = true;
              user.hasAccountBillingData = false;
            }

            user.isUser = true;
            return user;
          });
          commit(mutation.SET_BILLING_USERS, users);
          resolve(users);
        })
        .catch(err => {
          captureError(err);
          reject(err);
        });
    } else {
      resolve(state.billingUsers);
    }
  });
}

export function fetchAccounts({ commit, state }) {
  return new Promise((resolve, reject) => {
    if (state.billingAccounts.length === 0) {
      Accounts.getAll({
        filter: {
          include: ["billingData"]
        }
      })
        .then(res => {
          const accounts = res.map(ac => {
            if (ac.billingData == null) {
              ac.hasBillingData = false;
            } else {
              ac.hasBillingData = true;
            }
            ac.isAccount = true;
            ac.hasAccountBillingData = true;
            return ac;
          });
          commit(mutation.SET_BILLING_ACCOUNTS, accounts);
          resolve(accounts);
        })
        .catch(err => {
          reject(err);
        });
    } else {
      resolve(state.billingAccounts);
    }
  });
}

export function fetchInvoices({ commit }, { filter, where }) {
  return new Promise((resolve, reject) => {
    Invoices.listing({ filter, where })
      .then(({ data, meta }) => {
        if (meta && meta.skip === 0) {
          commit(mutation.UPDATE_TOTAL_INVOICES, meta.count);
        }
        data.forEach(invoice => {
          invoice.total = formatMoney(invoice.total);
        });
        commit(mutation.SET_INVOICES, data);
        resolve({ data, meta });
      })
      .catch(err => {
        console.log(err);
        captureError(err);
        reject(err);
      });
  });
}

export function download({}, { satWsId, formats = "pdf" }) {
  return new Promise((resolve, reject) => {
    Invoices.download({ satWsId, formats })
      .then(res => resolve(res))
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function mail({}, satWsId) {
  return new Promise((resolve, reject) => {
    Invoices.mail(satWsId)
      .then(res => resolve(res))
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function satWSRegistrationStatus({}, rfc) {
  return new Promise((resolve, reject) => {
    if (rfc == null) return reject();
    Invoices.csdLookup(rfc)
      .then(res => resolve(res))
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

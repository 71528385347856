export const SET_DEFAULT_ORIGIN = "setDefaultOrigin";
export const CLEAR_DEFAULT_ORIGIN = "clearDefaultOrigin";
export const SET_ONLINE_DRIVERS = "setOnlineDrivers";
export const SET_ACTIVE_SERVICES = "setActiveServices";
export const SET_SERVICE_SELECTED = "setServiceSelected";
export const SET_SELECTED_DRIVER = "setSelectedDriver";
export const SET_DRIVER_POSITION = "setDriverPosition";
export const SET_WALLETS = "setWallets";
export const UPDATE_WALLET = "updateWallet";
export const DELETE_ONLINE_DRIVER = "deleteOnlineDriver";

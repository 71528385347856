import * as mutation from "./mutations-types";
import defaultState from "./state";

export default {
  [mutation.UPDATE_CITIES_FILTER_TEXT](state, text) {
    state.citiesFilter.text = text;
  },
  [mutation.SET_CITIES](state, cities) {
    state.cities = [...cities];
  },
  [mutation.SET_SELECTED_CITY](state, city) {
    state.selectedCity = city;
  },
  [mutation.RESET_CITIES_STATE](state) {
    Object.assign(state, defaultState());
  }
};

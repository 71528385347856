export default {
  // Warehouses getters
  getWarehouses(state) {
    return state.warehouses;
  },
  getWarehousesTotal(state) {
    return state.warehousesTotal;
  },
  getWarehousesFilteredByClientId(state) {
    return state.warehousesFilter.clientId;
  },

  // Parcels getters
  getParcels(state) {
    return state.parcels;
  },
  getParcelsTotal(state) {
    return state.parcelsTotal;
  },
  getParcelsFilterDateOrder(state) {
    return state.parcelsFilter.dateOrder;
  },
  getParcelsFilterText(state) {
    return state.parcelsFilter.text;
  },
  getParcelsFilterStatus(state) {
    return state.parcelsFilter.status;
  },
  getParcelsFilterDeliveryMode(state) {
    return state.parcelsFilter.deliveryMode;
  },
  getParcelsFilterFromDate(state) {
    return state.parcelsFilter.fromDate;
  },
  getParcelsFilterUntilDate(state) {
    return state.parcelsFilter.untilDate;
  },
  getParcelsFilterClientId(state) {
    return state.parcelsFilter.clientId;
  },
  getParcelsPaginationPerPage(state) {
    return state.parcelsPagination.perPage;
  },
  getParcelsPaginationCurrentPage(state) {
    return state.parcelsPagination.currentPage;
  },
  // Collections getters
  getCollections(state) {
    return state.collections;
  },
  getCollectionsTotal(state) {
    return state.collectionsTotal;
  },
  getCollectionsFilterDateOrder(state) {
    return state.collectionsFilter.dateOrder;
  },
  getCollectionsFilterText(state) {
    return state.collectionsFilter.text;
  },
  getCollectionsFilterStatus(state) {
    return state.collectionsFilter.status;
  },
  getCollectionsPaginationPerPage(state) {
    return state.collectionsPagination.perPage;
  },
  getCollectionsPaginationCurrentPage(state) {
    return state.collectionsPagination.currentPage;
  },
  // Deliveries getters
  getDeliveries(state) {
    return state.deliveries;
  },
  getDeliveriesTotal(state) {
    return state.deliveriesTotal;
  },
  getDeliveriesFilterDateOrder(state) {
    return state.deliveriesFilter.dateOrder;
  },
  getDeliveriesFilterText(state) {
    return state.deliveriesFilter.text;
  },
  getDeliveriesFilterStatus(state) {
    return state.deliveriesFilter.status;
  },
  getDeliveriesPaginationPerPage(state) {
    return state.deliveriesPagination.perPage;
  },
  getDeliveriesPaginationCurrentPage(state) {
    return state.deliveriesPagination.currentPage;
  }
};

export const RESET = "reset";
export const UPDATE_SELECTED_DRIVER = "updateSelectedDriver";
export const SET_DRIVERS_FILTER = "setDriversFilter";
export const ADD_DRIVERS = "addDrivers";
export const DELETE_DRIVERS = "deleteDrivers";
export const ADD_DRIVER = "addDriver";
export const ACTIVATE_DRIVER = "update_status:activate";
export const UPDATE_STATUS = "update_status";

export const SET_LISTING = "SET_LISTING";
export const UPDATE_LISTING_FILTER_TEXT = "UPDATE_LISTING_FILTER_TEXT";
export const UPDATE_LISTING_FILTER_STATUS = "UPDATE_LISTING_FILTER_STATUS";
export const UPDATE_LISTING_FILTER_SORT_ORDER =
  "UPDATE_LISTING_FILTER_SORT_ORDER";
export const UPDATE_LISTING_FILTER_DATE = "UPDATE_LISTING_FILTER_DATE";
export const UPDATE_LISTING_FILTER_DATE_FROM =
  "UPDATE_LISTING_FILTER_DATE_FROM";
export const UPDATE_LISTING_FILTER_DATE_UNTIL =
  "UPDATE_LISTING_FILTER_DATE_UNTIL";
export const UPDATE_LISTING_FILTER_SELECT_ALL =
  "UPDATE_LISTING_FILTER_SELECT_ALL";
export const UPDATE_LISTING_FILTER_SELECTED = "UPDATE_LISTING_FILTER_SELECTED";
export const UPDATE_LISTING_TOTAL = "UPDATE_LISTING_TOTAL";

const defaultState = {
  driver: null,
  origin: null,
  user: null,
  destinations: [],
  selectedDestination: -1,
  estimation: null,
  date: new Date(),
  optimize: false,
  respectDestination: false
};

export default defaultState;

export default {
  cityOptions(state) {
    return [{ text: "Todas", value: null }].concat(
      state.cities.map(el => ({
        value: el.id,
        text: el.name
      }))
    );
  },

  abbrMapCityOptions(state) {
    return [{ text: "Todas", value: null }].concat(
      state.cities.map(el => ({
        value: el.id,
        text: el.abbreviation,
        position: el.position
      }))
    );
  },

  getCitiesFilterText(state) {
    return state.citiesFilter.text;
  },

  getCity(state) {
    return state.selectedCity;
  }
};

import ClientType from "@zubut/common/src/constants/clients/type";

const defaultState = {
  clients: [],
  clientsFilter: [],
  clientsCheckboxFilter: [],
  create: {
    type: ClientType.NUM_CLIENT,
    companyName: "",
    parentCompany: null,
    businessLine: null,
    contactName: "",
    phone: "",
    email: "",
    password: "zubut",
    address: {},
    addressReferences: ""
  }
};

export default defaultState;

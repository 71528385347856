import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("zRoles");

const Roles = Object.assign(
  {},
  {
    relation: "",
    defaultAccess(role) {
      return loopback.get("/zRoles/defaultAccess", {
        params: {
          role
        }
      });
    }
  },
  modelObject
);

export default Roles;

import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Vehicles");

const vehicles = Object.assign(
  {},
  {
    relation: "",

    delete(vehicleId) {
      return loopback.delete(`/Vehicles/${vehicleId}`);
    },

    edit(vehicleId, data) {
      return loopback.patch(`/Vehicles/${vehicleId}`, data);
    }
  },
  modelObject
);

export default vehicles;

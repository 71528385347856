import * as mutation from "./mutations-types";
import Status from "@/constants/clients/status";
import defaultState from "./state";

export default {
  [mutation.UPDATE_STATUS](state, { status, id }) {
    const updateStatusClient = state.clients.map(client => {
      if (client.id === id) {
        client.status = status;
        client.statusName = Status.get[status];
      }
      return user;
    });
    state.clients = updateStatusClient;
  },

  [mutation.ACTIVATE_CLIENT](state, id) {
    const activateClients = state.clients.map(client => {
      if (client.id === id) {
        client.status = Status.NUM_ACTIVE;
        client.statusName = Status.get[Status.NUM_ACTIVE];
      }
      return user;
    });
    state.clients = activateClients;
  },

  [mutation.RESET_CLIENT_CREATION](state) {
    const s = defaultState.create;
    state.create = { ...s };
  },

  [mutation.SET_ADDRESS](state, address) {
    state.create.address = address;
  },

  [mutation.SET_ADDRESS_REFERENCES](state, addressReferences) {
    state.create.addressReferences = addressReferences;
  },

  [mutation.SET_BUSINESS_LINE](state, businessLine) {
    state.create.businessLine = businessLine;
  },

  [mutation.SET_CLIENTS](state, clients) {
    state.clients = [...clients];
  },

  [mutation.SET_CLIENTS_FILTER](state, clients) {
    state.clientsFilter = clients;
  },

  [mutation.SET_CLIENTS_CHECKBOX_FILTER](state, clients) {
    state.clientsCheckboxFilter = clients;
  },
  [mutation.SET_CLIENT_TYPE](state, type) {
    state.create.type = type;
  },

  [mutation.SET_COMPANY_NAME](state, companyName) {
    state.create.companyName = companyName;
  },

  [mutation.SET_CONTACT_NAME](state, contactName) {
    state.create.contactName = contactName;
  },

  [mutation.SET_PHONE](state, phone) {
    state.create.phone = phone;
  },

  [mutation.SET_EMAIL](state, email) {
    state.create.email = email;
  },

  [mutation.SET_PASSWORD](state, password) {
    state.create.password = password;
  },

  [mutation.SET_PARENT_COMPANY](state, parentCompany) {
    state.create.parentCompany = parentCompany;
  }
};

export function getConnectionState(state) {
  return state.isConnected;
}

export function getRoleAccessCities(state) {
  return state.user.role.access.Ciudades;
}

export function getSessionUserId(state) {
  return state.user.id;
}

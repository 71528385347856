import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("ServiceIncidences");

const servicesIncidences = Object.assign(
  {},
  {
    relation: "",

    validate(id) {
      return loopback.post(`/ServiceIncidences/${id}/validate`, {
        accepted: true
      });
    }
  },
  modelObject
);

export default servicesIncidences;

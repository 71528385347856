import mutations from "./mutations";
import getters from "./getters";
import state from "./state";

export default {
  namespaced: true,
  state: Object.assign({}, state),
  mutations,
  getters
};

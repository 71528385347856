import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Admins");

let Admins = Object.assign(
  {},
  {
    relation: "",
    login(data) {
      return loopback.post(`/Admins/login`, data);
    },
    logout(data) {
      return loopback.post(`/Admins/logout`, data);
    },
    createAdmin(data) {
      return loopback.post("/Admins/createAdmin", data);
    },
    listing({ filter = {}, where = {} }) {
      return loopback.get("/Admins/listing", {
        params: { filter, where }
      });
    },
    setUserPasswordToDefault(id) {
      return loopback.post(`/Admins/${id}/passwordReset`);
    },
    editAdmin(id, data) {
      return loopback.patch(`/Admins/${id}`, data);
    },
    editAccess(data) {
      return loopback.post("/Admins/editAccess", data);
    },
    changeUserStatus(id, status) {
      return loopback.post(`/Admins/${id}/changeStatus`, { status });
    },
    changePassword(data) {
      return loopback.post("/Admins/change-password", data);
    }
  },
  modelObject
);

export default Admins;

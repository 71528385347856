import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";
import FilterModalityOptions from "@/constants/transactions/filterModalityOptions";

modelObject.setModel("TransactionReports");

const TransactionReports = Object.assign(
  {},
  {
    relation: "",

    listing({ filter = {}, where = {}, addColumns = [], selectedFilter }) {
      const routeDirectory = {
        [FilterModalityOptions.DRIVERS_ACCOUNTING]:
          "/TransactionReports/listing/drivers",
        [FilterModalityOptions.CUSTOMER_BALANCE]:
          "/TransactionReports/listing/clients",
        [FilterModalityOptions.TRANSACTION_HISTORY]:
          "/TransactionReports/listing"
      };

      filter.order = filter.order || "createdAt DESC";
      return loopback.get(routeDirectory[selectedFilter], {
        params: { filter, where, addColumns }
      });
    },

    details(id) {
      return loopback.get(`/TransactionReports/${id}/details`);
    }
  },
  modelObject
);

export default TransactionReports;

export function getDefaultOrigin(state) {
  return state.default_origin;
}

export function getOnlineDrivers(state) {
  return state.onlineDrivers;
}

export function getOnlineDriverById(state) {
  return driverId => state.onlineDrivers.find(driver => driver.id === driverId);
}

export function getUserWallets(state) {
  return state.wallets;
}

import { captureError } from "@/plugins/error-tracking";
import ServiceStatus from "@zubut/common/src/constants/services/status";
import ReservationStatus from "@/constants/serviceCenter/status-reservation";
import Reservations from "@/services/reservations";
import ReservationBlocks from "@/services/reservationBlocks";
import Services from "@/services/services";
import router from "@/router.js";
import { endOfDay, startOfDay } from "date-fns";
import { dateRange } from "@zubut/common/src/utils/time";
import LayoutType from "@/constants/serviceCenter/layout-type";
import * as mutation from "./mutations-types";

const buildRequest = ({
  state,
  pagination = { skip: 0, limit: 10 },
  reservationStatus,
  cityId
}) => {
  const isKanbanLayout = state.layout === LayoutType.NUM_KANBAN;
  const filter = {
    ...pagination,
    order: `${state.filters.sortBy} ${
      isKanbanLayout ? "asc" : state.filters.sortOrder
    }`
  };

  const where = { like: state.filters.search };

  where.status = [ServiceStatus.NUM_CREATED, ServiceStatus.NUM_ACTIVE];

  /* Set client filter */
  if (state.filters.clientId.length > 0) {
    where.clientId = { inq: state.filters.clientId };
  }

  /* Set vehicle type filter */
  if (state.filters.vehicleType !== null) {
    where.vehicleType = state.filters.vehicleType;
  }

  /* Set date filter */
  if (state.filters.date != null) {
    const range = dateRange(state.filters.date);
    where.range = range;
  }

  /* Set status type filter */
  if (reservationStatus !== null || state.filters.status !== null) {
    const status = reservationStatus ?? state.filters.status;
    switch (status) {
      case ReservationStatus.NUM_CREATED:
        where.status = [ServiceStatus.NUM_CREATED];
        where.driversId = { eq: null };
        break;
      case ReservationStatus.NUM_IN_ROUTE:
        where.status = [ServiceStatus.NUM_ACTIVE];
        break;
      case ReservationStatus.NUM_WITHOUT_DRIVER:
        where.driversId = { eq: null };
        break;
      case ReservationStatus.NUM_ASSIGNED:
        where.status = [ServiceStatus.NUM_CREATED];
        where.driversId = { neq: null };
        break;
      case ReservationStatus.NUM_SCHEDULED:
        // this.filterDate = null;
        where.status = [ServiceStatus.NUM_CREATED];
        where.driversId = { neq: null };
        where.range = { from: new Date() };
        break;
      case ReservationStatus.NUM_LATE:
        // this.filterDate = null;
        where.status = [ServiceStatus.NUM_CREATED];
        where.driversId = { neq: null };
        where.range = { until: new Date() };
        break;
    }
  }

  /* Set city filter */
  if (cityId) {
    where.cityId = cityId;
  }

  /* Set date range filter */
  if (state.layout === LayoutType.NUM_KANBAN) {
    where.range = {
      from: startOfDay(new Date()),
      until: endOfDay(new Date())
    };
  }
  return { filter, where };
};

export function getReservations(
  { commit, state, dispatch, rootState },
  params
) {
  if (state.layout === LayoutType.NUM_KANBAN) {
    return dispatch("getKanbanReservations", params);
  } else {
    const cityId = rootState.cities.selectedCity;
    const request = buildRequest({
      state,
      pagination: params?.pagination,
      cityId
    });
    return new Promise((resolve, reject) => {
      Reservations.services(request)
        .then(({ data, meta }) => {
          commit(mutation.SET_RESERVATIONS, data);
          if (meta && meta.skip === 0) {
            commit(mutation.UPDATE_TOTAL_ITEMS, meta.count);
          }
          resolve({ data, meta });
        })
        .catch(err => {
          captureError(err);
          reject(err);
        });
    });
  }
}

export function getKanbanReservations({ dispatch, commit }, params) {
  return Promise.all([
    dispatch("getCreatedReservations", params),
    dispatch("getAssinedReservations", params),
    dispatch("getActiveReservations", params)
  ]).then(() => {
    if (params?.pagination?.skip === 0) {
      // Reset page count in case of pagination is disabled in kanban
      commit(mutation.SET_FILTER_PAGE, 1);
    }
  });
}

export function getCreatedReservations({ commit, state, rootState }, params) {
  const cityId = rootState.cities.selectedCity;
  const request = buildRequest({
    state,
    pagination: params?.pagination,
    reservationStatus: ReservationStatus.NUM_CREATED,
    cityId
  });
  return new Promise((resolve, reject) => {
    Reservations.services(request)
      .then(({ data, meta }) => {
        if (params?.pagination?.skip > 0) {
          commit(mutation.ADD_CREATED_RESERVATIONS, data);
        } else {
          commit(mutation.SET_CREATED_RESERVATIONS, data);
        }
        resolve({ data, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getAssinedReservations({ commit, state, rootState }, params) {
  const cityId = rootState.cities.selectedCity;
  const request = buildRequest({
    state,
    pagination: params?.pagination,
    reservationStatus: ReservationStatus.NUM_ASSIGNED,
    cityId
  });
  return new Promise((resolve, reject) => {
    Reservations.services(request)
      .then(({ data, meta }) => {
        if (params?.pagination?.skip > 0) {
          commit(mutation.ADD_ASSIGNED_RESERVATIONS, data);
        } else {
          commit(mutation.SET_ASSIGNED_RESERVATIONS, data);
        }
        resolve({ data, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getActiveReservations({ commit, state, rootState }, params) {
  const cityId = rootState.cities.selectedCity;
  const request = buildRequest({
    state,
    pagination: params?.pagination,
    reservationStatus: ReservationStatus.NUM_IN_ROUTE,
    cityId
  });
  return new Promise((resolve, reject) => {
    Reservations.services(request)
      .then(({ data, meta }) => {
        if (params?.pagination?.skip > 0) {
          commit(mutation.ADD_ACTIVE_RESERVATIONS, data);
        } else {
          commit(mutation.SET_ACTIVE_RESERVATIONS, data);
        }
        resolve({ data, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function cancelService({ commit }, service) {
  return new Promise((resolve, reject) => {
    Services.cancel(service)
      .then(res => {
        if (res && res.response) {
          commit(mutation.REMOVE_RESERVATION, service.id);
          commit(mutation.REMOVE_CREATED_RESERVATION, service.id);
          commit(mutation.REMOVE_ASSIGNED_RESERVATION, service.id);
          service.status = ServiceStatus.NUM_ZUBUT_CANCELLED;
          commit(mutation.ADD_COMPLETED_RESERVATION, service);
          resolve(res);
        } else {
          reject();
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function completeService({ commit }, service) {
  return new Promise((resolve, reject) => {
    Services.finish(service.id)
      .then(res => {
        if (res.data.status === 200) {
          commit(mutation.REMOVE_RESERVATION, service.id);
          commit(mutation.REMOVE_ACTIVE_RESERVATION, service.id);
          service.status = ServiceStatus.NUM_FINISHED;
          commit(mutation.ADD_COMPLETED_RESERVATION, service);
          resolve(res);
        } else {
          reject();
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function assignService({ commit, dispatch }, { serviceId, driverId }) {
  return new Promise((resolve, reject) => {
    Services.assignService(serviceId, driverId)
      .then(res => {
        commit(mutation.UPDATE_RESERVATION, { id: serviceId, driverId });
        commit(mutation.REMOVE_CREATED_RESERVATION, serviceId);
        dispatch("updateReservation", serviceId);
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function reassignService({ commit, dispatch }, { serviceId, driverId }) {
  return new Promise((resolve, reject) => {
    Services.reassignService(serviceId, driverId)
      .then(res => {
        commit(mutation.UPDATE_RESERVATION, { id: serviceId, driverId });
        commit(mutation.REMOVE_CREATED_RESERVATION, serviceId);
        dispatch("updateReservation", serviceId);
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function checkInService({ commit }, { serviceId, driverId }) {
  return new Promise((resolve, reject) => {
    Reservations.driverCheckIn({ serviceId, driverId })
      .then(res => {
        commit(mutation.REMOVE_ASSIGNED_RESERVATION, serviceId);
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function updateReservation({ commit, state }, serviceId) {
  const where = { id: serviceId };
  if (state.clientId) where.clientId = state.clientId;
  if (state.vehicleType) where.vehicleType = state.vehicleType;
  where.status = [ServiceStatus.NUM_CREATED, ServiceStatus.NUM_ACTIVE];

  Reservations.services({
    where,
    filter: { skip: 0, limit: 1, order: "createdAt desc" }
  })
    .then(({ data: services }) => {
      if (services.length > 0) {
        const service = services[0];
        if (service.status === ServiceStatus.NUM_CREATED && !service.driverId) {
          commit(mutation.ADD_CREATED_RESERVATION, service);
        } else if (
          service.status === ServiceStatus.NUM_CREATED &&
          service.driverId
        ) {
          commit(mutation.ADD_ASSIGNED_RESERVATION, service);
        } else if (service.status === ServiceStatus.NUM_ACTIVE) {
          commit(mutation.ADD_ACTIVE_RESERVATION, service);
        }
      } else {
        commit(mutation.TOGGLE_UPDATE_INDICATOR, true);
      }
    })
    .catch(err => {
      captureError(err);
    });
}

export function handleIncomingServiceUpdate(
  { state, commit, dispatch },
  { serviceId }
) {
  if (router.currentRoute.query.tab !== "renta-hora") {
    commit(mutation.TOGGLE_UPDATE_INDICATOR, true);
    return;
  }

  dispatch("updateReservation", serviceId);

  const index = state.reservations.findIndex(_ => _.id == serviceId);
  if (index < 0) {
    commit(mutation.TOGGLE_UPDATE_INDICATOR, true);
    return;
  }
}

export function cleanCompletedReservations({ commit }) {
  commit(mutation.DELETE_COMPLETED_RESERVATIONS);
}

export function reviewAdditionalHours(
  { commit, dispatch },
  { reservationBlockId, decision, serviceId }
) {
  return new Promise((resolve, reject) => {
    ReservationBlocks.reviewAdditionalHoursRequest(reservationBlockId, decision)
      .then(() => {
        dispatch("updateReservation", serviceId);
        commit(mutation.REMOVE_ACTIVE_RESERVATION, serviceId);
        resolve(decision);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

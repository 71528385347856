import Admins from "@/services/admins";
import Roles from "@/services/roles";
import { captureError } from "@/plugins/error-tracking";
import Http from "@zubut/common/src/constants/http";
import * as mutation from "./mutation-types";

export function createAdmin({}, data) {
  return new Promise((resolve, reject) => {
    Admins.createAdmin(data)
      .then(res => {
        if (res.admin && res.admin.id) {
          resolve();
        } else {
          const err = new Error("Error desconocido");
          reject(err);
          captureError(err);
        }
      })
      .catch(err => {
        reject(err);
        captureError(err);
      });
  });
}

export function getAdminsFilter({ state, commit }) {
  return new Promise((resolve, reject) => {
    Admins.listing({
      filter: { skip: 0, limit: 5, order: "lastLogin DESC" }
    })
      .then(admins => {
        commit(mutation.SET_ADMINS_FILTER, admins.data);
        resolve(admins);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getListing({ commit }, filter) {
  return new Promise((resolve, reject) => {
    Admins.listing(filter)
      .then(res => {
        commit(mutation.SET_LISTING_ADMINS, res.data);
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function setUserPasswordToDefault({}, id) {
  return new Promise((resolve, reject) => {
    Admins.setUserPasswordToDefault(id)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

// This method is called updateUser because the CardDetailInfo component calls
// the same method for all implementations
export function updateUser({}, { id, data }) {
  return new Promise((resolve, reject) => {
    Admins.editAdmin(id, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        if (err.statusCode === Http.UNPROCESSABLE_ENTITY) {
          reject(new Error("Correo electrónico ya se encuentra registrado"));
        } else {
          reject(err);
        }
      });
  });
}

export function updateStatus({}, { status, id }) {
  return new Promise((resolve, reject) => {
    Admins.changeUserStatus(id, status)
      .then(res => {
        if (res.data.status == 200) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getDefaultRoleAccess(_, role) {
  return new Promise((resolve, reject) => {
    Roles.defaultAccess(role)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function editAccess({ dispatch }, { id, access }) {
  return new Promise((resolve, reject) => {
    Admins.editAccess({
      adminId: id,
      access
    })
      .then(role => {
        dispatch("auth/updateAbilities", { id, role }, { root: true });
        resolve();
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

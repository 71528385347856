import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Parcels");

const parcels = Object.assign(
  {},
  {
    relation: "",
    collectable({ filter = {}, where = {} }) {
      const params = {};
      if (Object.keys(filter).length) {
        params.filter = filter;
      }
      if (Object.keys(where).length) {
        params.where = where;
      }
      return loopback.get("/Parcels/collectable", {
        params
      });
    },

    details(id) {
      return loopback.get(`/Parcels/${id}/details`);
    },

    listing({ filter = {}, where = {} }) {
      const params = {};
      if (Object.keys(filter).length) {
        params.filter = filter;
      }
      if (Object.keys(where).length) {
        params.where = where;
      }
      return loopback.get("/Parcels/listing", {
        params
      });
    },

    logs(id, { filter = {}, where = {} }) {
      const params = {};
      if (Object.keys(filter).length) {
        params.filter = filter;
      }
      if (Object.keys(where).length) {
        params.where = where;
      }
      return loopback.get(`/Parcels/${id}/logListing`, { params });
    },

    shippingLabel(parcelId) {
      return loopback.get("/Parcels/shippingLabel", {
        params: { parcelId, format: "png" }
      });
    },

    cancel(parcelId) {
      return loopback.post("/Parcels/cancel", { parcels: [parcelId] });
    },

    markAsLost(parcelId) {
      return loopback.post(`/Parcels/${parcelId}/markAsLost`);
    },

    setReturnedToOrigin(parcelId) {
      return loopback.post(`/Parcels/${parcelId}/setReturnedToOrigin`);
    }
  },
  modelObject
);

export default parcels;

import parseISO from "date-fns/parseISO";
import { format } from "@zubut/common/src/utils/time";
import * as _l from "lodash/lang";
import { formatCreatedAtDate } from "@zubut/common/src/utils/time";

const createChargeItem = service => ({
  createdAt: formatCreatedAtDate(
    format(parseISO(service.createdAt, "dd/LLL/yyyy HH:mm"))
  ),
  clientName: service.user ? service.user.name : undefined,
  driverName: service.driver ? service.driver.name : undefined,
  charge: `$ ${parseFloat(service.estimatedRate).toFixed(2)}`,
  clientId: service.clientId,
  id: service.id
});

export function pendingServices(state) {
  if (!_l.isEmpty(state.pendingFilterClient)) {
    return state.pendingServices.reduce((res, service) => {
      if (service.clientId === state.pendingFilterClient) {
        res.push(createChargeItem(service));
      }
      return res;
    }, []);
  }
  return state.pendingServices.map(service => createChargeItem(service));
}

export function pendingClients(_, getters) {
  return getters.pendingServices
    .map(service => ({
      clientId: service.clientId,
      clientName: service.clientName
    }))
    .filter(
      (client, index, array) =>
        array.findIndex(val => val.clientId === client.clientId) === index
    );
}

export function date(state) {
  return state.date;
}

export function invoices(state) {
  return state.invoices;
}

export function pendingFilterClient(state) {
  return state.pendingFilterClient;
}

export function getDate(state) {
  return state.date;
}

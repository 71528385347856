const defaultState = {
  services: [],
  filters: {
    search: "",
    sortBy: "createdAt",
    sortOrder: "desc",
    vehicleType: null,
    alert: null,
    clientId: [],
    status: null
  },
  totalItems: 0,
  hasUpdates: false
};

export default defaultState;

import DateFilters from "@zubut/common/src/constants/filters/date";

/**
 * @typedef  {Object} Transaction
 * @property {number} id    - The transaction id
 * @property {number} unit  - The number of units cotained in the transaction
 */

/**
 * @typedef {"accounting" | "delivery"} TransactionFilterType
 */

/**
 * @typedef TransactionsState
 * @property {Array.<Transaction>}   listing                 - The items contained in the listing
 * @property {number}                listingTotal            - The total items that correspond to the filters applied
 * @property {object}                listingFilter           - The filters applied to the listing
 * @property {string}                listingFilter.search    - The text typed in the search input
 * @property {string}                listingFilter.sortBy    - The text typed in the search input
 * @property {TransactionFilterType} listingFilter.type      - The type of filter that the admin selected when entering the listing
 * @property {"desc" | "asc"}        listingFilter.sortOrder - The order of the sort by property
 * @property {Array.<string>}        listingFilter.clientId  - The selected client to filter by
 * @property {string}                listingFilter.driverId  - The selected driver to filter by
 * @property {number}                listingFilter.date      - The selected type of date filter
 * @property {date}                  listingFilter.dateFrom  - The selected start of the custom date
 * @property {date}                  listingFilter.dateUntil - The selected end of the custom date
 * @property {number}                listingFilter.service   - The selected typ of service to filter by
 * @property {number}                listingFilter.columns   - The fields to be shown in the transactions listing
 */
/** @type {TransactionsState} */
const defaultState = {
  listing: [],
  listingTotal: 0,
  listingFilter: {
    search: "",
    sortBy: "createdAt",
    type: null,
    sortOrder: "desc",
    clientId: [],
    driverId: null,
    date: DateFilters.NUM_THIS_WEEK,
    dateFrom: null,
    dateUntil: null,
    service: null,
    columns: [
      "status",
      "serviceId",
      "deliveryType",
      "attendedTime",
      "concept",
      "subtotal",
      "tax",
      "total"
    ]
  }
};

export default defaultState;

import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Reservations");

const reservations = Object.assign(
  {},
  {
    relation: "",

    adminCreate(data) {
      return loopback.post("/Reservations/adminCreate", data);
    },

    estimation(data) {
      return loopback.get("/Reservations/estimation", { params: data });
    },

    services({ filter, where }) {
      return loopback.get("/Reservations/services", {
        params: { filter, where }
      });
    },

    driverCheckIn(data) {
      return loopback.post("/Reservations/driverCheckIn", data);
    }
  },
  modelObject
);

export default reservations;

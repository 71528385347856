export default {
  clientsOptions(state) {
    return [{ text: "Todos", value: null }].concat(
      state.clients.map(client => ({
        value: client.id,
        text: `${client.name}`
      }))
    );
  },

  clientsFilterOptions(state) {
    return [{ text: "Todos", value: null }].concat(
      state.clientsFilter.map(client => ({
        value: client.id,
        text: `${client.name}`
      }))
    );
  },
  clientsCheckboxFilterOptions(state) {
    return state.clientsCheckboxFilter;
  },
  parentCompanyOptions(state) {
    return [
      { text: "Selecciona la compañía a la que pertenece", value: null }
    ].concat(
      state.clients.reduce((arr, client) => {
        if (client.type === 0 || client.type === 1) {
          arr.push({
            value: client.id,
            text: `${client.name}`
          });
        }
        return arr;
      }, [])
    );
  }
};

import Vue from "vue";
import store from "@/store";
import router from "@/router.js";
import "@/app/components/globals";
import "./plugins";
import i18n from "./plugins/vue-i18n";
import "./index.scss";
import _debounce from "lodash/debounce";

/* Sync routes with auth module */
store.dispatch("auth/syncRouter", router);

export default new Vue({
  i18n,
  el: "#root",
  store,
  router,
  created() {
    this.onResize = _debounce(this.onResize, 300);
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.$store.commit("setWindowWidth", window.innerWidth);
    }
  },
  render: h => h("router-view")
});

import { captureError } from "@/plugins/error-tracking";
import Cities from "@/services/cities";
import * as mutation from "./mutations-types";

function convertRoleAccessCitiesIntoArray(cities) {
  return Object.keys(cities).filter(key => cities[key] === true);
}

function filterCitiesWithAccess(cities, cityNamesWithAccess) {
  return cities.filter(city => {
    return cityNamesWithAccess.some(cityNameWithAccess => {
      return cityNameWithAccess === city.name;
    });
  });
}

export function getCities({ state, commit, rootGetters }) {
  return new Promise((resolve, reject) => {
    if (!state.cities.length) {
      Cities.getAll({})
        .then(cities => {
          const cityNamesWithAccess = convertRoleAccessCitiesIntoArray(
            rootGetters["auth/getRoleAccessCities"]
          );
          const citiesWithAccess = filterCitiesWithAccess(
            cities,
            cityNamesWithAccess
          );

          commit(mutation.SET_CITIES, citiesWithAccess);
          resolve(citiesWithAccess);
        })
        .catch(err => {
          captureError(err);
          reject(err);
        });
    }
    resolve();
  });
}

export function resetCitiesState({ commit }) {
  commit(mutation.RESET_CITIES_STATE);
}

import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Clients");

let Clients = Object.assign(
  {},
  {
    relation: "",

    billingData: Object.assign({}, { relation: "/billingData" }, modelObject),

    getCurrentId() {
      const user = JSON.parse(localStorage.getItem("loopback-token"));
      if (user) {
        return user.userId;
      }
      return null;
    },
    listing({ filter = {}, where = {} }) {
      return loopback.get("/Clients/listing", {
        params: { filter, where }
      });
    },
    nameListing({ filter = {}, where = {} }) {
      return loopback.get("/Clients/nameListing", {
        params: { filter, where }
      });
    },
    emailRecovery(data) {
      return loopback.post(`/Clients/emailRecovery`, data);
    },
    changeUserStatus(id, status) {
      return loopback.post(`/Clients/${id}/changeStatus`, { status });
    },
    setUserPasswordToDefault(id) {
      return loopback.post(`/Clients/${id}/passwordReset`);
    },
    updateAdminProperties(data) {
      return loopback.post(`/Clients/updateAdminProperties`, data);
    },
    servicesSums(data) {
      return loopback.post(`/Clients/servicesSums`, data);
    },
    userQuickStats(data) {
      return loopback.post(`/Clients/userQuickStats`, data);
    },
    dailyServiceAverage(data) {
      return loopback.post(`/Clients/dailyServiceAverage`, data);
    },
    averageServiceTime(data) {
      return loopback.post(`/Clients/averageServiceTime`, data);
    },
    getRatesOnDemand(id) {
      return loopback.get(`/Clients/${id}/ratesOndemandOrDefault`);
    },
    getRatesPackages(id) {
      return loopback.get(`/Clients/${id}/ratesPackagesOrDefault`);
    },
    getRatesReservation(id) {
      return loopback.get(`/Clients/${id}/ratesReservationOrDefault`);
    },
    getRatesCrossDock(id) {
      return loopback.get(`/Clients/${id}/ratesCrossDockOrDefault`);
    },
    getTopUsers(data) {
      return loopback.post(`/Clients/getTopUsers`, data);
    },
    getWallletData(id) {
      return loopback.get(`/Clients/${id}/wallet`);
    },
    getWeightedDestinations(data) {
      return loopback.post(`/Clients/getWeightedDestinations`, data);
    },
    setDistinguished(id, value) {
      return loopback.post(`/Clients/${id}/setDistinguished`, {
        distinguished: value
      });
    },
    createClient(data) {
      return loopback.post(`/Clients/createClient`, data);
    },
    activateUser(data) {
      return loopback.post("/Clients/userActivation", data);
    },
    editUser(id, data) {
      return loopback.patch(`/Clients/${id}`, data);
    },
    updateAdditional(id, data) {
      return loopback.post(`/Clients/${id}/additional`, data);
    },
    branchListing({ filter = {}, where = {} }) {
      return loopback.get("/Clients/branchListing", {
        params: { filter, where }
      });
    },
    createBranch(id, data) {
      return loopback.post(`/Clients/${id}/createBranch`, data);
    },
    addBranch(id, data) {
      return loopback.post(`/Clients/${id}/addBranch`, data);
    },
    getBillingData(clientId) {
      return loopback.get(`/Clients/getBillingData`, { params: { clientId } });
    }
  },
  modelObject
);

export default Clients;

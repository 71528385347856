import billing from "./billing";
import charges from "./charges";
import drivers from "./drivers";
import notifications from "./notifications";
import services from "./services";
import deliveries from "./deliveries";
import statistics from "./statistics";
import clients from "./clients";
import admins from "./admins";
import userActivity from "./userActivity";
import wallet from "./wallet";
import warehouses from "./warehouses";
import deliveryCenter from "./deliveryCenter";
import transactions from "./transactions";

export default {
  billing,
  charges,
  drivers,
  notifications,
  services,
  deliveries,
  statistics,
  clients,
  admins,
  userActivity,
  wallet,
  warehouses,
  deliveryCenter,
  transactions
};

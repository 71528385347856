import * as mutation from "./mutations-types";

export default {
  [mutation.ADD_SERVICES](state, services) {
    state.services = [...services];
  },

  [mutation.REMOVE_SERVICE](state, id) {
    const services = state.services.filter(service => {
      return service.id !== id;
    });
    state.totalItems = state.totalItems - 1;
    state.services = [...services];
  },

  [mutation.UPDATE_SERVICE](state, data) {
    const update = state.services.map(service => {
      if (service.id !== data.id) return service;
      return {
        ...service,
        ...data
      };
    });
    state.services = [...update];
  },

  [mutation.TOGGLE_UPDATE_INDICATOR](state, hasUpdates) {
    state.hasUpdates = hasUpdates;
  },

  [mutation.UPDATE_TOTAL_ITEMS](state, total) {
    state.totalItems = total;
  },

  [mutation.SET_FILTER_SEARCH](state, search) {
    state.filters.search = search;
  },

  [mutation.SET_FILTER_ALERT](state, alert) {
    state.filters.alert = alert;
  },

  [mutation.SET_FILTER_SORT_BY](state, sortBy) {
    state.filters.sortBy = sortBy;
  },

  [mutation.SET_FILTER_SORT_ORDER](state, sortOrder) {
    state.filters.sortOrder = sortOrder;
  },

  [mutation.SET_FILTER_VEHICLE_TYPE](state, vehicleType) {
    state.filters.vehicleType = vehicleType;
  },

  [mutation.SET_FILTER_CLIENT](state, clientId) {
    state.filters.clientId = clientId;
  },

  [mutation.SET_FILTER_STATUS](state, status) {
    state.filters.status = status;
  }
};

const ADMINS = "administradores";
const ADMINS_CREATE = "crear-administrador";
const CLIENTS = "clientes";
const CLIENT_CREATE = "crear-cliente";
const LINK_BRANCH = "vincular-sucursal";
const CREATE_BRANCH = "crear-sucursal";
const DRIVERS = "mensajeros";
const INVOICES = "facturacion";
const LOGIN = "inicio-sesion";
const LOGS = "logs";
const NOTIFICATION = "notificaciones";
const PARCELS = "paquetes";
const RECONNECT = "reconectar";
const RESTRICTED_ACCESS = "acceso-restringido";
const RETRIEVE_PASSWORD = "recuperar-contrasena";
const SERVICES = "servicios";
const DELIVERIES = "entregas";
const SERVICE_CENTER = "centro-servicios";
const STATISTICS = "estadisticas";
const STATISTICS_LEGACY = "estadisticas-legacy";
const TRANSACTIONS = "transacciones";
const WALLET = "wallet";

export default {
  ADMINS,
  ADMINS_CREATE,
  CLIENTS,
  CLIENT_CREATE,
  LINK_BRANCH,
  CREATE_BRANCH,
  DRIVERS,
  INVOICES,
  LOGIN,
  LOGS,
  NOTIFICATION,
  PARCELS,
  RECONNECT,
  RESTRICTED_ACCESS,
  RETRIEVE_PASSWORD,
  SERVICES,
  DELIVERIES,
  SERVICE_CENTER,
  STATISTICS,
  STATISTICS_LEGACY,
  TRANSACTIONS,
  WALLET
};

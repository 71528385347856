/* eslint global-require: "off" */
import { captureError } from "@/plugins/error-tracking";
import Drivers from "@/services/drivers";
import Services from "@/services/services";
import Wallets from "@/services/wallet";
import ServiceStatus from "@/constants/services/status";
import rotateImage from "@/utils/rotateImage";
import { degreesToRadians } from "@/utils/math";
import * as mutation from "./mutations-types";
import currency from "currency.js";

export function connected(_, data) {
  console.log("respuesta: ", data.data);
}

export function updateDriverPosition({ state, commit }, data) {
  return new Promise(resolve => {
    const driverIndex = state.onlineDrivers.findIndex(
      driver => driver.id === data.driverId
    );
    if (driverIndex >= 0) {
      const driver = state.onlineDrivers[driverIndex];
      driver.position = driver.position ? driver.position : {};
      driver.position.lat = data.lat;
      driver.position.lng = data.lng;
      driver.orientation = { ...data.orientation };
      driver.onService = data.onService;
      driver.serviceId = data.serviceId || "";
      if (driver.alpha) {
        const driverImage = require("../../../assets/img/ic-moto-vip.png");
        rotateImage(
          driverImage,
          64,
          64,
          degreesToRadians(driver.orientation.bearing)
        ).then(image => {
          driver.icon = image;
          commit(mutation.SET_DRIVER_POSITION, driver, driverIndex);
          resolve();
        });
      } else {
        const driverImage = require("../../../assets/img/ic-moto.png");
        rotateImage(
          driverImage,
          64,
          64,
          degreesToRadians(driver.orientation.bearing)
        ).then(image => {
          driver.icon = image;
          commit(mutation.SET_DRIVER_POSITION, driver, driverIndex);
          resolve();
        });
      }
    } else {
      resolve();
    }
  });
}

export function serviceUpdate({ dispatch }, data) {
  const serviceId = data?.serviceId ?? data.id;
  const serviceType = data?.serviceType;
  dispatch(
    "services/handleSocketServiceUpdate",
    { serviceId, serviceType },
    { root: true }
  );
}

export function updateDrivers({ dispatch }, data) {
  if (data && data.driverId && data.serviceId) {
    dispatch("getActiveServices", {
      driversId: data.driverId,
      serviceId: data.serviceId
    });
  } else if (data && data.driverLogout) {
    dispatch("driverLogout", data.driverId);
  }
}

export function verifyDriverOnline({ state }, driverId) {
  return new Promise(resolve => {
    state.onlineDrivers.forEach(driver => {
      if (driver.id === driverId) {
        resolve(true);
      }
    });
    resolve(false);
  });
}

export function setDriverData({ dispatch }, item) {
  item.image = `https://zubut.com/images/profile/${item.image_sm}`;
  if (item.lat && item.lng) {
    item.position = {
      lat: parseFloat(item.lat),
      lng: parseFloat(item.lng)
    };

    delete item.lat;
    delete item.lng;
  }
  dispatch("setDriverStatus", item);
  if (item.driverId) {
    item.id = item.driverId;
  }
}

function getDriversWithServices(onlineDrivers) {
  return new Promise(resolve => {
    Drivers.offlineDriversWithService().then(res => {
      res.drivers.forEach(driver => {
        onlineDrivers.push(driver);
      });
      resolve(onlineDrivers);
    });
  });
}

// TODO delete this code if we don't have any issues
// export function getOnlineDrivers({ commit, dispatch }) {
//   return new Promise((resolve, reject) => {
//     Drivers.getOnlineDrivers().then(res => {
//       const onlineDrivers = res.drivers;
//       getDriversWithServices(onlineDrivers)
//         .then(res => {
//           res.forEach(item => {
//             dispatch("setDriverData", item);
//           });
//           commit(mutation.SET_ONLINE_DRIVERS, res);
//           resolve(res);
//         })
//         .catch(error => {
//           captureError(error);
//           reject(error);
//         });
//     });
//   });
// }

export function getActiveServices(
  { state, commit, dispatch },
  { driversId, serviceId }
) {
  Services.find({
    filter: {
      where: {
        and: [
          {
            status: ServiceStatus.NUM_ACTIVE
          },
          {
            driversId
          }
        ]
      },
      include: ["driver", "user"]
    }
  }).then(res => {
    if (driversId === state.selectedDriver.id) {
      state.selectedDriver.activeServices = res.length;
      let currentService = {};
      for (let i = 0; i < res.length; i++) {
        const serv = res[i];
        if (serv.id === serviceId && serviceId === state.serviceSelected.id) {
          currentService = serv;
          break;
        }
      }
      dispatch("selectService", currentService);
      commit(mutation.SET_ACTIVE_SERVICES, res);
      dispatch(
        "setDriverStatus",
        state.onlineDrivers[state.selectedDriver.index]
      );
    } else {
      dispatch("verifyDriverOnline", driversId).then(inOnlineDrivers => {
        if (inOnlineDrivers) {
          dispatch("updateDriversServices", {
            driversId,
            services: res.length
          });
        } else {
          Drivers.findById({
            id: driversId
          }).then(driver => {
            driver.activeServices = res.length;
            dispatch("setDriverData", driver);
            state.onlineDrivers.push(driver);
          });
        }
      });
    }
  });
}

export function updateDriversServices(
  { state, dispatch },
  { driversId, services }
) {
  for (let i = 0; i < state.onlineDrivers.length; i++) {
    const d = state.onlineDrivers[i];
    if (d.id === driversId) {
      d.activeServices = services;
      dispatch("setDriverStatus", d);
      if (d.statusNum === 1) {
        state.onlineDrivers.splice(i, 1);
      }
      break;
    }
  }
}

export function setDriverStatus(_, driver) {
  if (driver.activeServices > 0) {
    driver.status = "En envío";
    driver.statusNum = 3;
  } else if (driver.position) {
    driver.status = "Disponible";
    driver.statusNum = 2;
  } else {
    driver.status = "Sin coordenadas";
    driver.statusNum = 1;
  }
}

export function driverLogout({ commit }, driverId) {
  commit(mutation.DELETE_ONLINE_DRIVER, driverId);
}

export async function fetchUserWallets({ commit }, { filter, where }) {
  try {
    const { data, meta } = await Wallets.listing({ filter, where });
    if (data != null) {
      data.forEach(wallet => {
        wallet.amount = currency(wallet.amount).divide(100).value;
      });
      commit(mutation.SET_WALLETS, data);
      return meta;
    }
    throw { status: 404, message: "No existen wallets" };
  } catch (err) {
    captureError(err);
    throw err;
  }
}

export function payUserWallet({ commit }, { clientId, walletId }) {
  return new Promise((resolve, reject) => {
    Wallets.payPending(clientId)
      .then(res => {
        if (res.paid) {
          const amount = currency(res.currentBalance).divide(100).value;
          commit(mutation.UPDATE_WALLET, {
            amount: amount,
            pending: "",
            pendingAmount: 0,
            id: walletId
          });
          resolve();
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function modifyUserWallet({ commit }, { amount, walletId }) {
  return new Promise((resolve, reject) => {
    Wallets.patch({ id: walletId }, { amount })
      .then(res => {
        const amount = currency(res.amount).divide(100).value;
        commit(mutation.UPDATE_WALLET, {
          amount: amount,
          id: walletId
        });
        resolve({ ...res, amount });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

const defaultState = {
  default_origin: "",
  onlineDrivers: [],
  activeServices: [],
  serviceSelected: {},
  selectedDriver: {},
  wallets: []
};

export default defaultState;

import ServiceStatus from "@/constants/services/status";
import ServiceType from "@zubut/common/src/constants/services/type";
import { diffInSeconds } from "@zubut/common/src/utils/time";

export default {
  methods: {
    calculateServiceRate(service) {
      if (service.type === ServiceType.NUM_RENT_PER_HOUR)
        return parseFloat(service.estimatedRate);
      const shouldCharge = this.shouldChargeForCanceledService(service);
      if (!shouldCharge) return 0;
      return service.status === ServiceStatus.NUM_CLIENT_CANCELLED
        ? parseFloat(service.cost)
        : parseFloat(service.estimatedRate);
    },

    shouldChargeForCanceledService(service) {
      const driverCancelled =
        service.status === ServiceStatus.NUM_DRIVER_CANCELLED;
      const zubutCancelled =
        service.status === ServiceStatus.NUM_ZUBUT_CANCELLED;
      const userCancelledOnTime =
        service.status === ServiceStatus.NUM_CLIENT_CANCELLED &&
        this.serviceWithinCancelTime(service);
      const shouldNotCharge =
        driverCancelled || zubutCancelled || userCancelledOnTime;
      return !shouldNotCharge;
    },

    serviceWithinCancelTime(service) {
      if (typeof service.cancelledOnTime !== "undefined") {
        return service.cancelledOnTime;
      }
      if (service.cancelledAt) {
        if (diffInSeconds(service.cancelledAt, service.createdAt) <= 300)
          return true;
        return false;
      }
      return (
        !service.resume ||
        !service.resume.finishedAt ||
        service.serviceTime <= 5
      );
    }
  }
};

const CREATED = "Creado";
const REQUESTING = "Solicitando";
const ACTIVE = "Activo";
const COMPLETED = "Finalizado";
const CLIENT_CANCELLED = "Cancelado por cliente";
const ZUBUT_CANCELLED = "Cancelado por Zubut";
const DRIVER_CANCELLED = "Cancelado por mensajero";
const SCHEDULED = "Agendado";
const REVIEW_PENDING = "Por revisar";
const DRIVERS_NOT_AVAILABLE = "Mensajeros no disponibles";

const NUM_CREATED = 0;
const NUM_REQUESTING = 1;
const NUM_ACTIVE = 2;
const NUM_FINISHED = 3;
const NUM_CLIENT_CANCELLED = 4;
const NUM_DRIVER_CANCELLED = 5;
const NUM_SCHEDULED = 6;
const NUM_REVIEW_PENDING = 7;
const NUM_DRIVERS_NOT_AVAILABLE = 8;
const NUM_ZUBUT_CANCELLED = 9;

/* Unofficial status, used for filters only */
const NUM_ARCHIVED = 12;
const NUM_INCIDENCE = 13;
const NUM_RECALCULATED = 14;

const CANCELLED_STATUS = [
  NUM_CLIENT_CANCELLED,
  NUM_ZUBUT_CANCELLED,
  NUM_DRIVER_CANCELLED
];

const COMPLETED_STATUS = [
  NUM_FINISHED,
  NUM_CLIENT_CANCELLED,
  NUM_DRIVER_CANCELLED,
  NUM_REVIEW_PENDING,
  NUM_ZUBUT_CANCELLED
];

const get = {};
get[NUM_CREATED] = CREATED;
get[NUM_REQUESTING] = REQUESTING;
get[NUM_ACTIVE] = ACTIVE;
get[NUM_FINISHED] = COMPLETED;
get[NUM_CLIENT_CANCELLED] = CLIENT_CANCELLED;
get[NUM_ZUBUT_CANCELLED] = ZUBUT_CANCELLED;
get[NUM_DRIVER_CANCELLED] = DRIVER_CANCELLED;
get[NUM_SCHEDULED] = SCHEDULED;
get[NUM_REVIEW_PENDING] = REVIEW_PENDING;
get[NUM_DRIVERS_NOT_AVAILABLE] = DRIVERS_NOT_AVAILABLE;

const cancelledStatus = [
  NUM_CLIENT_CANCELLED,
  NUM_ZUBUT_CANCELLED,
  NUM_DRIVER_CANCELLED
];

const finalStatus = [NUM_FINISHED, ...cancelledStatus];

const completedOptions = finalStatus.map(val => ({
  text: get[val],
  value: val
}));

const options = Object.keys(get).map(key => ({
  text: get[key],
  value: parseInt(key)
}));

const isValidStatus = status => {
  return get[status] !== undefined;
};

export default {
  get,
  options,
  completedOptions,
  isValidStatus,
  NUM_CREATED,
  NUM_REQUESTING,
  NUM_ACTIVE,
  NUM_FINISHED,
  NUM_CLIENT_CANCELLED,
  NUM_ZUBUT_CANCELLED,
  NUM_DRIVER_CANCELLED,
  NUM_SCHEDULED,
  NUM_REVIEW_PENDING,
  NUM_DRIVERS_NOT_AVAILABLE,
  NUM_ARCHIVED,
  NUM_INCIDENCE,
  NUM_RECALCULATED,
  CREATED,
  REQUESTING,
  ACTIVE,
  COMPLETED,
  CLIENT_CANCELLED,
  ZUBUT_CANCELLED,
  DRIVER_CANCELLED,
  SCHEDULED,
  REVIEW_PENDING,
  DRIVERS_NOT_AVAILABLE,
  CANCELLED_STATUS,
  COMPLETED_STATUS
};

import _cloneDeep from "lodash/cloneDeep";
import * as mutation from "./mutations-types";

export default {
  [mutation.SET_BILLING_ACCOUNTS](state, accounts) {
    state.billingAccounts = _cloneDeep(accounts);
  },

  [mutation.SET_BILLING_USERS](state, users) {
    state.billingUsers = users;
  },

  [mutation.SET_DATE](state, date) {
    state.date = date;
  },

  [mutation.SET_INVOICES](state, invoices) {
    state.invoices = _cloneDeep(invoices);
  },

  [mutation.UPDATE_TOTAL_INVOICES](state, total) {
    state.totalInvoices = total;
  }
};

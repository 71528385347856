import DateConstants from "@zubut/common/src/constants/filters/date";

const defaultState = {
  listing: [],
  listingTotal: 0,
  listingFilter: {
    search: "",
    sortBy: "createdAt",
    sortOrder: "desc",
    text: null,
    activity: null,
    severity: null,
    adminId: null,
    clientId: null,
    driverId: null,
    role: null,
    date: DateConstants.NUM_TODAY,
    dateFrom: null,
    dateUntil: null,
    service: null
  }
};

export default defaultState;

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import multipoint from "./multipoint";
import reservations from "./reservations";
import route from "./route";
import warehouses from "./warehouses";

export default {
  namespaced: true,
  state: { ...state },
  getters,
  mutations,
  modules: {
    multipoint,
    route,
    reservations,
    warehouses
  }
};

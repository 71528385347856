import cloneDeep from "lodash/cloneDeep";
import defaultState from "./state";
import * as mutation from "./mutations-types";

export default {
  [mutation.RESET](state) {
    const s = defaultState;
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },

  [mutation.SET_NEW_SERVICE_ORIGIN](state, origin) {
    state.origin = cloneDeep(origin);
  },

  [mutation.ADD_NEW_SERVICE_DESTINATION](state, destination) {
    state.destinations = [...state.destinations, cloneDeep(destination)];
  },

  [mutation.SET_SELECTED_DESTINATION_INDEX](state, index) {
    state.selectedDestination = index;
  },

  [mutation.SET_NEW_SERVICE_DRIVER](state, driver) {
    state.driver = cloneDeep(driver);
  },

  [mutation.SET_NEW_SERVICE_USER](state, user) {
    state.user = cloneDeep(user);
  },

  [mutation.DELETE_NEW_SERVICE_DESTINATION](state, index) {
    state.destinations = state.destinations.filter((_, i) => i !== index);
  },

  [mutation.SET_NEW_SERVICE_ESTIMATION](state, estimation) {
    state.estimation = cloneDeep(estimation);
  },

  [mutation.SET_NEW_SERVICE_DATE](state, date) {
    state.date = date;
  }
};

import VueRouter from "vue-router";
import Vue from "vue";
import Routes from "@/constants/routes/paths";

const ServiceCenter = () =>
  import("@/app/views/ServiceCenter/ServiceCenter.vue");
const Drivers = () => import("@/app/views/Drivers/Drivers.vue");
const DriverDetail = () => import("@/app/views/DriverDetail/DriverDetail.vue");
const ExpressCreation = () =>
  import("@/app/views/ExpressCreation/ExpressCreation.vue");
const Forgot = () => import("@/app/views/Login/Forgot.vue");
const MainLayout = () => import("@/app/layouts/MainLayout.vue");
const InitLayout = () => import("@/app/layouts/InitLayout.vue");
const Login = () => import("@/app/views/Login/Login.vue");
const Invoices = () => import("@/app/views/Invoice/Invoices.vue");
const Reconnect = () => import("@/app/views/Login/Reconnect.vue");
const RestrictedAccess = () => import("@/app/views/RestrictedAccess.vue");
const ServiceCreation = () =>
  import("@/app/views/ServiceCreation/ServiceCreation.vue");
const Services = () => import("@/app/views/Services/Services.vue");
const Deliveries = () => import("@/app/views/Deliveries/Deliveries.vue");
const ServiceDetail = () =>
  import("@/app/views/ServiceDetail/ServiceDetail.vue");
const DeliveryDetail = () =>
  import("@/app/views/DeliveryDetail/DeliveryDetail.vue");
const Statistics = () => import("@/app/views/Statistics/Statistics.vue");
const Transactions = () => import("@/app/views/Transactions/Transactions.vue");
const Admins = () => import("@/app/views/Admins/Admins.vue");
const AdminsCreate = () => import("@/app/views/Admins/AdminsCreate.vue");
const AdminDetail = () => import("@/app/views/AdminDetail/AdminDetail.vue");
const Clients = () => import("@/app/views/Clients/Clients.vue");
const ClientCreate = () => import("@/app/views/ClientCreate/ClientCreate.vue");
const ClientDetail = () => import("@/app/views/ClientDetail/ClientDetail.vue");
const ClientLinkBranch = () =>
  import(
    "@/app/views/ClientDetail/ClientLinkBranch/ClientLinkBranchMobile.vue"
  );
const ClientCreateBranch = () =>
  import(
    "@/app/views/ClientDetail/ClientCreateBranch/ClientCreateBranchMobile.vue"
  );
const Logs = () => import("@/app/views/Logs/Logs.vue");
const WarehouseDetail = () =>
  import("@/app/views/WarehouseDetail/WarehouseDetail.vue");
const ParcelDetail = () =>
  import("@/app/views/ParcelDetail/ParcelDetailMobile.vue");
const OneToOneInvoice = () =>
  import("@/app/views/OneToOneInvoice/OneToOneInvoice.vue");
const ExcelInvoiceReceiver = () =>
  import("@/app/views/Invoice/ExcelInvoice/ExcelInvoiceReceiver.vue");
const ExcelInvoiceIssuer = () =>
  import("@/app/views/Invoice/ExcelInvoice/ExcelInvoiceIssuer.vue");
const Notification = () =>
  import("@/app/views/Notifications/NotificationMobile.vue");
const NotFound = () => import("@/app/views/NotFound.vue");

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "*",
      redirect: {
        name: "home"
      }
    },
    {
      path: "/",
      name: "home",
      component: MainLayout,
      redirect: {
        name: "serviceCenter"
      },
      children: [
        {
          path: `/${Routes.RESTRICTED_ACCESS}`,
          name: "restricted-access",
          components: {
            default: RestrictedAccess
          }
        },
        {
          path: `/${Routes.SERVICE_CENTER}`,
          name: "serviceCenter",
          components: {
            default: ServiceCenter
          },
          meta: { requiresAuth: true },
          children: [
            {
              path: `bodega/:id`,
              query: {
                tab: "guias"
              },
              name: "warehouseDetail",
              components: {
                default: WarehouseDetail
              }
            },
            {
              path: `crear/express`,
              name: "expressCreation",
              components: {
                default: ExpressCreation
              }
            },
            {
              path: `servicio/:id`,
              name: "serviceCenter-serviceDetail",
              components: {
                default: ServiceDetail
              },
              meta: { requiresAuth: true }
            }
          ]
        },
        {
          path: `/${Routes.SERVICE_CENTER}/crear-servicio`,
          name: "serviceCreation",
          components: {
            default: ServiceCreation
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.ADMINS}`,
          name: "admins",
          components: {
            default: Admins
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.ADMINS}/:id`,
          name: "adminDetail",
          components: {
            default: AdminDetail
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.ADMINS_CREATE}`,
          name: "adminsCreate",
          components: {
            default: AdminsCreate
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.PARCELS}/:id`,
          name: "parcelDetail",
          components: {
            default: ParcelDetail
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.CLIENTS}`,
          name: "clients",
          components: {
            default: Clients
          },
          meta: { requiresAuth: true },
          children: [
            {
              path: `${Routes.CLIENT_CREATE}`,
              name: "clientCreate",
              components: {
                default: ClientCreate
              }
            }
          ]
        },
        {
          path: `/${Routes.CLIENTS}/:id`,
          name: "clientDetail",
          components: {
            default: ClientDetail
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.CLIENTS}/:id/${Routes.LINK_BRANCH}`,
          name: "linkBranch",
          components: {
            default: ClientLinkBranch
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.CLIENTS}/:id/${Routes.CREATE_BRANCH}`,
          name: "createBranch",
          components: {
            default: ClientCreateBranch
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.DRIVERS}`,
          name: "drivers",
          components: {
            default: Drivers
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.DRIVERS}/:id`,
          name: "driverDetail",
          components: {
            default: DriverDetail
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.SERVICES}`,
          name: "services",
          components: {
            default: Services
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.DELIVERIES}`,
          name: "deliveries",
          components: {
            default: Deliveries
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.SERVICES}/:id`,
          name: "serviceDetail",
          components: {
            default: ServiceDetail
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.DELIVERIES}/:id`,
          name: "deliveryDetail",
          components: {
            default: DeliveryDetail
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.LOGS}`,
          name: "logs",
          components: {
            default: Logs
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.STATISTICS}`,
          name: "statistics",
          components: {
            default: Statistics
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.INVOICES}`,
          name: "invoices",
          components: {
            default: Invoices
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.INVOICES}/uno-a-uno`,
          name: "one-to-one-invoice",
          components: {
            default: OneToOneInvoice
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.INVOICES}/uno-a-uno/:id`,
          name: "edit-one-to-one-invoice",
          components: {
            default: OneToOneInvoice
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.INVOICES}/excel-receptor`,
          name: "excel-invoice-receiver",
          components: {
            default: ExcelInvoiceReceiver
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.INVOICES}/excel-emisor`,
          name: "excel-invoice-issuer",
          components: {
            default: ExcelInvoiceIssuer
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.TRANSACTIONS}`,
          name: "transactions",
          components: {
            default: Transactions
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.NOTIFICATION}`,
          name: "notification",
          components: {
            default: Notification
          },
          meta: { requiresAuth: true }
        }
      ]
    },
    {
      path: "/inicio",
      name: "start",
      component: InitLayout,
      redirect: {
        name: "login"
      },
      children: [
        {
          path: `/${Routes.LOGIN}`,
          name: "login",
          components: {
            default: Login
          }
        },
        {
          path: `/${Routes.RECONNECT}`,
          name: "reconnect",
          components: {
            default: Reconnect
          }
        },
        {
          path: `/${Routes.RETRIEVE_PASSWORD}`,
          name: "forgot",
          components: {
            default: Forgot
          }
        }
      ]
    },
    { path: "/404", name: "404", component: NotFound }
  ]
});

export default router;

import currency from "currency.js";

export function makeShortUUID(id = "") {
  return id ? id.substr(id.length - 6) : "";
}

/**
 * Validate that the string has the correct name format.
 * Valid format: Cadena
 * Invalid format: Caden4
 * @param {string} name String to validate.
 */
export function validateName(name) {
  const nameRejectRegexp = /\d/;
  return !nameRejectRegexp.test(name) && name.length > 2;
}

/**
 * Validate that string have the correct email format
 * Valid format: cadena@cadena.co,
 */
export function validateEmail(email) {
  // eslint-disable-next-line
  const mailRegexp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return mailRegexp.test(String(email));
}

/**
 * Validate that string have the correct phone format
 * Valid format: (33) 3761-8690, 3335976089, +52 3335976089
 */
export function validatePhone(phone) {
  // eslint-disable-next-line
  const phoneRegEx = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$$/;
  return phoneRegEx.test(phone) && phone.length >= 10;
}

/**
 * Add commas to string of numbers: e.g. 1000.00 -> 1,000.00
 */
const numberFormat = Intl.NumberFormat("en-US", {
  style: "decimal",
  maximumFractionDigits: 2
});

export function numberFormatter(val) {
  return numberFormat.format(val);
}

/**
 * Removes accents and transforms text to lowercase
 */
export function normalize(text) {
  if (text == null) return null;
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}

export function moneyFormatter(value) {
  return "$ " + currency(value);
}

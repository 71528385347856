const defaultState = {
  routeServices: [],
  totalServices: 0,
  searchText: "",
  clientFilter: [],
  vehicleFilter: null,
  hasUpdates: false
};

export default defaultState;

import Vue from "vue";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import router from "@/router";

const sentryOptions = {
  Vue,
  dsn:
    process.env.NODE_ENV !== "development"
      ? process.env.VUE_APP_SENTRY_DSN
      : null,
  beforeSend(event, hint) {
    const statusCodePropertyExist = hint?.originalException?.hasOwnProperty(
      "statusCode"
    );
    if (statusCodePropertyExist) {
      const isNotServerError = hint?.originalException?.statusCode < 500;
      if (isNotServerError) {
        return null;
      }
    }

    return event;
  },
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "localhost:8081",
        "admin.zubut.com",
        "staging-admin.zubut.com",
        /^\//
      ]
    })
  ],
  ignoreErrors: [
    "NavigationDuplicated",
    "Navigation cancelled from",
    "Non-Error exception captured with keys: message"
  ],
  tracesSampleRate: 0.5,
  release: "zubut-admin@" + process.env.VUE_APP_VERSION,
  environment: process.env.NODE_ENV
};

if (process.env.NODE_ENV === "staging") {
  sentryOptions.debug = true;
}

if (process.env.NODE_ENV !== "test") {
  Sentry.init(sentryOptions);
}

// Wrapping the Sentry methods allows us to add custom behaviour or easily change
// the error tracking provider if needed
const captureError = err => Sentry.captureException(err);
const setUser = user => Sentry.setUser(user);
const clearUser = () => Sentry.configureScope(scope => scope.setUser(null));

Vue.prototype.$captureError = captureError;

export { captureError, setUser, clearUser };

import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Invoices");

let Invoices = Object.assign(
  {},
  {
    relation: "",

    listing({ filter = {}, where = {} }) {
      filter.order = filter.order || "createdAt DESC";
      return loopback.get("/Invoices/listing", {
        params: { filter, where }
      });
    },

    parseBillingInfo(file) {
      let url = "/Invoices/parseBillingInfo";
      const bodyFormData = new FormData();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };
      bodyFormData.append("billing", file);
      return loopback.post(url, bodyFormData, config);
    },

    generate(data) {
      return loopback.post("/Invoices/generate", data);
    },

    generateMultiple(data) {
      return loopback.post("/Invoices/generateMultiple", data);
    },

    download({ satWsId, formats }) {
      return loopback.get("/Invoices/downloadInvoice", {
        params: { satWsId, formats },
        responseType: "blob"
      });
    },

    mail(satWsId) {
      return loopback.post("/Invoices/sendBySatWsId", { satWsId });
    },

    csdLookup(rfc) {
      return loopback.get(`/Invoices/csdLookup`, { params: { rfc } });
    }
  },
  modelObject
);

export default Invoices;

export const SET_RESERVATIONS = "setReservations";
export const ADD_RESERVATIONS = "addReservations";
export const REMOVE_RESERVATION = "removeReservation";
export const UPDATE_RESERVATION = "updateReservation";
export const TOGGLE_UPDATE_INDICATOR = "toggleUpdateIndicator";

export const ADD_CREATED_RESERVATION = "addCreatedReservation";
export const REMOVE_CREATED_RESERVATION = "removeCreatedReservation";
export const ADD_CREATED_RESERVATIONS = "addCreatedReservations";
export const SET_CREATED_RESERVATIONS = "setCreatedReservations";

export const ADD_ASSIGNED_RESERVATION = "addAssignedReservation";
export const REMOVE_ASSIGNED_RESERVATION = "removeAssignedReservation";
export const ADD_ASSIGNED_RESERVATIONS = "addAssignedReservations";
export const SET_ASSIGNED_RESERVATIONS = "setAssignedReservations";

export const ADD_ACTIVE_RESERVATION = "addActiveReservation";
export const REMOVE_ACTIVE_RESERVATION = "removeActiveReservation";
export const ADD_ACTIVE_RESERVATIONS = "addActiveReservations";
export const SET_ACTIVE_RESERVATIONS = "setActiveReservations";

export const ADD_COMPLETED_RESERVATION = "addCompletedReservation";
export const REMOVE_COMPLETED_RESERVATION = "removeCompletedReservation";
export const DELETE_COMPLETED_RESERVATIONS = "deleteCompletedReservations";

export const UPDATE_TOTAL_ITEMS = "updateTotalItems";

export const SET_FILTER_CLIENT = "setFilterClient";
export const SET_FILTER_ALERT = "setFilterAlert";
export const SET_FILTER_DATE = "setFilterDate";
export const SET_FILTER_SORT_BY = "setFilterSortBy";
export const SET_FILTER_SORT_ORDER = "setFilterSortOrder";
export const SET_FILTER_VEHICLE_TYPE = "setFilterVehicleType";
export const SET_FILTER_STATUS = "setFilterStatus";
export const SET_FILTER_SEARCH = "setFilterSearch";
export const SET_FILTER_FROM = "setFilterFrom";
export const SET_FILTER_UNTIL = "setFilterUntil";
export const SET_FILTER_PAGE = "setFilterPage";

export const SET_LAYOUT_TYPE = "setLayoutType";

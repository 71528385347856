export default {
  getServices(state) {
    return state.services;
  },
  getFilters(state) {
    return state.filters;
  },
  getTotalItems(state) {
    return state.totalItems;
  },
  getHasUpdates(state) {
    return state.hasUpdates;
  }
};

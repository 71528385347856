import { captureError } from "@/plugins/error-tracking";
import Services from "@/services/services";
import * as mutation from "./mutations-types";
import { formatSendPhone } from "@zubut/common/src/utils/strings";
import ServiceStatus from "@zubut/common/src/constants/services/status";

export function requestService({ dispatch, state, rootGetters }) {
  return new Promise((resolve, reject) => {
    const destinations = state.destinations.map(dest => {
      return {
        address: dest.data.address.address,
        position: dest.data.address.position,
        information: dest.data.information,
        name: dest.data.name,
        phone: formatSendPhone(dest.data.phone)
      };
    });
    const additional = state.additionalOptions;
    const vehicleType = state.vehicleType;
    const paymentMethod = state.paymentMethod;
    const km = state.rates.km;
    const estimatedTime = state.rates.minutes;
    const estimatedRate = state.rates.cost;
    let walletId = "";
    let cardId = "";
    if (paymentMethod === "wallet") {
      walletId = rootGetters["user/getWallet"].id;
    } else {
      cardId = state.cardId;
    }

    Services.request({
      destinations,
      additional,
      vehicleType,
      walletId,
      cardId,
      km,
      estimatedTime,
      estimatedRate
    })
      .then((res, err) => {
        if (err) {
          reject(err);
          return;
        }
        dispatch("reset");
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function createService({ state }) {
  return new Promise((resolve, reject) => {
    const serviceData = {
      destinations: state.destinations.map(item => ({
        ...item.data,
        ...item.data.address
      })),
      estimatedTime: state.rates.minutes,
      estimatedRate: state.rates.subtotal,
      km: state.rates.km,
      paid: false,
      status:
        state.driver?.id != null
          ? ServiceStatus.NUM_ACTIVE
          : ServiceStatus.NUM_CREATED,
      createdAt: new Date(),
      clientId: state.clientId,
      additional: state.additionalOptions,
      driversId: state.driver?.id || null,
      acceptedAt: new Date(),
      optimize: true,
      respectDestination: true,
      cityId: state.cityId,
      vehicleType: state.vehicleType
    };

    // TODO: Block when the user has not zubut credit
    if (state.walletId) {
      serviceData.walletId = state.walletId;
    } else if (state.cardId) {
      serviceData.cardId = state.cardId;
    }

    Services.create({
      data: serviceData
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function serviceRate({ commit, state, rootGetters }) {
  return new Promise((resolve, reject) => {
    const destinations = state.destinations.map(dest => {
      return {
        address: dest.data.address.address,
        position: dest.data.address.position,
        information: dest.data.information,
        name: dest.data.name,
        phone: dest.data.phone
      };
    });
    const additional = state.additionalOptions;
    additional.km = state.rates.km;
    const vehicleType = state.vehicleType;
    const clientId = state.clientId;

    Services.serviceRate({
      destinations,
      additional,
      vehicleType,
      clientId
    })
      .then(
        res => {
          const vehicleRates = {
            bikeKMCost: res.taxedParameters.bikeKMTotal,
            carKMCost: res.taxedParameters.carKMTotal,
            ecoKMCost: res.taxedParameters.ecoKMTotal
          };
          commit(mutation.SET_RATES_COST, res.taxedRate);
          commit(mutation.SET_RATES_SUBTOTAL, res.rate);
          commit(mutation.SET_RATES_TAX, res.tax);
          commit(mutation.SET_VEHICLE_RATES, vehicleRates);
          commit(mutation.SET_CITY_ID, res.cityId);
          commit(mutation.SET_CURRENCY_RATES, res.currency);
          resolve(res);
        },
        reason => {
          captureError(reason);
          reject(reason);
        }
      )
      .catch(reason => {
        captureError(reason);
        reject(reason);
      });
  });
}

export function setTimeAndDistance({ commit }, { km, minutes }) {
  commit(mutation.SET_RATES_MINUTES, minutes);
  commit(mutation.SET_RATES_KM, km);
}

export function reset({ commit }) {
  commit(mutation.RESET);
}

import { captureError } from "@/plugins/error-tracking";
import Services from "@/services/services";
import * as mutation from "./mutations-types";

export function getRouteServices({ commit }, { filter, where }) {
  return new Promise((resolve, reject) => {
    Services.listing({ filter, where })
      .then(({ data, meta }) => {
        commit(mutation.SET_ROUTE_SERVICES, data);
        if (meta.count != null) {
          commit(mutation.UPDATE_TOTAL_SERVICES_COUNT, meta.count);
        }
        resolve({ data, meta });
      })
      .catch(error => {
        captureError(error);
        reject(err);
      });
  });
}

export function review({}, { id, approve }) {
  let data = { approve: approve };
  return new Promise((resolve, reject) => {
    Services.review(id, data)
      .then(service => {
        resolve(service);
      })
      .catch(error => {
        captureError(error);
        reject(error);
      });
  });
}

export function archive({}, { serviceId }) {
  let id = { serviceId: serviceId };
  return new Promise((resolve, reject) => {
    Services.changeArchivedStatus(id)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        captureError(error);
        reject(error);
      });
  });
}

export function handleIncomingServiceUpdate({ commit }) {
  commit(mutation.TOGGLE_UPDATE_INDICATOR, true);
}

/**
 * Round a number to the indicated decimals.
 *
 * @returns {Number} 'n' rounded to 'precision' decimals,
 *  returns NaN if something fails
 * @param {Number} num - Number to round.
 * @param {Number} precision - Number of decimals to round to.
 */
export function roundTo(num, precision) {
  try {
    return Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision);
  } catch (_e) {
    return NaN;
  }
}

export function validateOnlyNumbers(num) {
  const numRegexp = /^[0-9]+$/;
  return numRegexp.test(num);
}

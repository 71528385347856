// Warehouses mutations
export const SET_WAREHOUSES = "setWarehouses";
export const UPDATE_WAREHOUSES_TOTAL = "updateWarehousesTotal";
export const UPDATE_WAREHOUSES_FILTER_CLIENT = "updateWarehousesFilterClient";

// Parcels mutations
export const SET_PARCELS = "setParcels";
export const UPDATE_PARCELS_FILTER_DATE_ORDER = "updateParcelsFilterDateOrder";
export const UPDATE_PARCELS_FILTER_TEXT = "updateParcelsFilterText";
export const UPDATE_PARCELS_FILTER_STATUS = "updateParcelsFilterStatus";
export const UPDATE_PARCELS_FILTER_DELIVERY_MODE =
  "updateParcelsFilterDeliveryMode";
export const UPDATE_PARCELS_FILTER_FROM = "updateParcelsFilterFrom";
export const UPDATE_PARCELS_FILTER_UNTIL = "updateParcelsFilterUntil";
export const UPDATE_PARCELS_FILTER_CLIENT = "updateParcelsFilterClient";
export const UPDATE_PARCELS_TOTAL = "updateParcelsTotal";
export const CLEAR_PARCELS_FILTERS = "clearParcelsFilters";

export const UPDATE_PARCELS_PAGINATION_PER_PAGE =
  "updateParcelsPaginationPerPage";
export const UPDATE_PARCELS_PAGINATION_CURRENT_PAGE =
  "updateParcelsPaginationCurrentPage";

export const UPDATE_PARCEL_STATUS = "updateParcelStatus";

// Collection Services mutations
export const SET_COLLECTIONS = "setCollections";
export const UPDATE_COLLECTIONS_FILTER_DATE_ORDER =
  "updateCollectionsFilterDateOrder";
export const UPDATE_COLLECTIONS_FILTER_TEXT = "updateCollectionsFilterText";
export const UPDATE_COLLECTIONS_FILTER_STATUS = "updateCollectionsFilterStatus";
export const UPDATE_COLLECTIONS_TOTAL = "updateCollectionsTotal";
export const CLEAR_COLLECTIONS_FILTERS = "clearCollectionsFilters";

export const UPDATE_COLLECTIONS_PAGINATION_PER_PAGE =
  "updateCollectionsPaginationPerPage";
export const UPDATE_COLLECTIONS_PAGINATION_CURRENT_PAGE =
  "updateCollectionsPaginationCurrentPage";

// Delivery Services mutations
export const SET_DELIVERIES = "setDeliveries";
export const UPDATE_DELIVERIES_FILTER_DATE_ORDER =
  "updateDeliveriesFilterDateOrder";
export const UPDATE_DELIVERIES_FILTER_TEXT = "updateDeliveriesFilterText";
export const UPDATE_DELIVERIES_FILTER_STATUS = "updateDeliveriesFilterStatus";
export const UPDATE_DELIVERIES_TOTAL = "updateDeliveriesTotal";
export const CLEAR_DELIVERIES_FILTERS = "clearDeliveriesFilters";

export const UPDATE_DELIVERIES_PAGINATION_PER_PAGE =
  "updateDeliveriesPaginationPerPage";
export const UPDATE_DELIVERIES_PAGINATION_CURRENT_PAGE =
  "updateDeliveriesPaginationCurrentPage";

export const UPDATE_SERVICE = "updateService";

export const TOGGLE_UPDATE_INDICATOR = "toggleUpdateIndicator";

import * as mutation from "./mutations-types";
import defaultState from "./state";
import _cloneDeep from "lodash/cloneDeep";

export default {
  [mutation.RESET](state) {
    const s = defaultState;
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },

  [mutation.CLEAN_ACCESS_TOKEN](state) {
    state.access_token = null;
  },

  [mutation.UPDATE_ACCESS_TOKEN](state, token) {
    state.access_token = token;
  },

  [mutation.CLEAN_USER](state) {
    state.user = null;
  },

  [mutation.UPDATE_USER](state, user) {
    state.user = user;
  },

  [mutation.UPDATE_USER_ROLE](state, role) {
    const user = _cloneDeep(state.user);
    user.role = role;
    state.user = user;
  },

  [mutation.UPDATE_IS_RECONNECTING](state, status) {
    state.isReconnecting = status;
  },

  [mutation.UPDATE_CONNECTION_STATE](state, status) {
    state.isConnected = status;
  }
};

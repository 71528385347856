import * as mutation from "./mutation-types";

export default {
  [mutation.SET_LISTING_ADMINS](state, admins) {
    state.listingAdmins = admins;
  },

  [mutation.SET_ADMINS_FILTER](state, admins) {
    state.adminsFilter = admins;
  }
};

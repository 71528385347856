import * as mutation from "./mutations-types";

export default {
  [mutation.SET_RESERVATIONS](state, reservations) {
    state.reservations = [...reservations];
  },

  [mutation.ADD_RESERVATIONS](state, reservations) {
    state.reservations = [...state.reservations, ...reservations];
  },

  [mutation.REMOVE_RESERVATION](state, id) {
    const remove = state.reservations.filter(service => {
      return service.id !== id;
    });

    state.totalItems = state.totalItems - 1;
    state.reservations = [...remove];
  },

  [mutation.UPDATE_RESERVATION](state, data) {
    const update = state.reservations.map(reservation => {
      if (reservation.id !== data.id) return reservation;
      return {
        ...reservation,
        ...data
      };
    });
    state.reservations = [...update];
  },

  [mutation.TOGGLE_UPDATE_INDICATOR](state, hasUpdates) {
    state.hasUpdates = hasUpdates;
  },

  [mutation.UPDATE_TOTAL_ITEMS](state, total) {
    state.totalItems = total;
  },

  [mutation.SET_FILTER_ALERT](state, alert) {
    state.filters.alert = alert;
  },

  [mutation.SET_FILTER_DATE](state, date) {
    state.filters.date = date;
  },

  [mutation.SET_FILTER_SEARCH](state, search) {
    state.filters.search = search;
  },

  [mutation.SET_FILTER_SORT_BY](state, sortBy) {
    state.filters.sortBy = sortBy;
  },

  [mutation.SET_FILTER_SORT_ORDER](state, sortOrder) {
    state.filters.sortOrder = sortOrder;
  },

  [mutation.SET_FILTER_VEHICLE_TYPE](state, vehicleType) {
    state.filters.vehicleType = vehicleType;
  },

  [mutation.SET_FILTER_CLIENT](state, clientId) {
    state.filters.clientId = clientId;
  },

  [mutation.SET_FILTER_STATUS](state, status) {
    state.filters.status = status;
  },

  [mutation.SET_FILTER_FROM](state, from) {
    state.filters.from = from;
  },

  [mutation.SET_FILTER_UNTIL](state, until) {
    state.filters.until = until;
  },

  [mutation.SET_FILTER_PAGE](state, page) {
    state.filters.page = page;
  },

  [mutation.SET_LAYOUT_TYPE](state, layout) {
    state.layout = layout;
  },

  [mutation.ADD_CREATED_RESERVATION](state, reservation) {
    state.created = [...state.created, reservation];
  },

  [mutation.REMOVE_CREATED_RESERVATION](state, reservationId) {
    state.created = state.created.filter(service => {
      return service.id !== reservationId;
    });
  },

  [mutation.SET_CREATED_RESERVATIONS](state, reservations) {
    state.created = [...reservations];
  },

  [mutation.ADD_CREATED_RESERVATIONS](state, reservations) {
    state.created = [...state.created, ...reservations];
  },

  [mutation.ADD_ASSIGNED_RESERVATION](state, reservation) {
    state.assigned = [...state.assigned, reservation];
  },

  [mutation.REMOVE_ASSIGNED_RESERVATION](state, reservationId) {
    state.assigned = state.assigned.filter(service => {
      return service.id !== reservationId;
    });
  },

  [mutation.ADD_ASSIGNED_RESERVATIONS](state, reservations) {
    state.assigned = [...state.assigned, ...reservations];
  },

  [mutation.SET_ASSIGNED_RESERVATIONS](state, reservations) {
    state.assigned = [...reservations];
  },

  [mutation.ADD_ACTIVE_RESERVATION](state, reservation) {
    state.active = [...state.active, reservation];
  },

  [mutation.REMOVE_ACTIVE_RESERVATION](state, reservationId) {
    state.active = state.active.filter(service => {
      return service.id !== reservationId;
    });
  },

  [mutation.ADD_ACTIVE_RESERVATIONS](state, reservations) {
    state.active = [...state.active, ...reservations];
  },

  [mutation.SET_ACTIVE_RESERVATIONS](state, reservations) {
    state.active = [...reservations];
  },

  [mutation.ADD_COMPLETED_RESERVATION](state, reservationService) {
    state.completed = [...state.completed, reservationService];
  },

  [mutation.DELETE_COMPLETED_RESERVATIONS](state) {
    state.completed = [];
  }
};

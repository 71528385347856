import Vue from "vue";

const moduleName = "Transacciones";

const READ_TRANSACTIONS = "Ver todas las transacciones";
const EXPORT_TRANSACTIONS = "Exportar todas las transacciones";
const READ_DRIVER_TRANSACTIONS = "Ver las transacciones de conductores";
const EXPORT_DRIVER_TRANSACTIONS = "Exportar las transacciones de conductores";
const READ_CUSTOMER_TRANSACTIONS = "Ver las transacciones de clientes";
const EXPORT_CUSTOMER_TRANSACTIONS = "Exportar las transacciones de clientes";

const HAS_ACCESS_READ_DRIVER_TRANSACTIONS = () =>
  Vue.prototype.$ability.can(READ_DRIVER_TRANSACTIONS, moduleName);

const HAS_ACCESS_READ_CUSTOMER_TRANSACTIONS = () =>
  Vue.prototype.$ability.can(READ_CUSTOMER_TRANSACTIONS, moduleName);

const HAS_ACCESS_READ_TRANSACTIONS = () =>
  Vue.prototype.$ability.can(READ_TRANSACTIONS, moduleName);

const HAS_ACCESS_EXPORT_TRANSACTIONS = () =>
  Vue.prototype.$ability.can(EXPORT_TRANSACTIONS, moduleName);

const HAS_ACCESS_EXPORT_DRIVER_TRANSACTIONS = () =>
  Vue.prototype.$ability.can(EXPORT_DRIVER_TRANSACTIONS, moduleName);

const HAS_ACCESS_EXPORT_CUSTOMER_TRANSACTIONS = () =>
  Vue.prototype.$ability.can(EXPORT_CUSTOMER_TRANSACTIONS, moduleName);

export default {
  moduleName,
  READ_TRANSACTIONS,
  EXPORT_TRANSACTIONS,
  READ_DRIVER_TRANSACTIONS,
  EXPORT_DRIVER_TRANSACTIONS,
  READ_CUSTOMER_TRANSACTIONS,
  EXPORT_CUSTOMER_TRANSACTIONS,
  HAS_ACCESS_READ_DRIVER_TRANSACTIONS,
  HAS_ACCESS_READ_CUSTOMER_TRANSACTIONS,
  HAS_ACCESS_READ_TRANSACTIONS,
  HAS_ACCESS_EXPORT_TRANSACTIONS,
  HAS_ACCESS_EXPORT_DRIVER_TRANSACTIONS,
  HAS_ACCESS_EXPORT_CUSTOMER_TRANSACTIONS
};

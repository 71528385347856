import cloneDeep from "lodash/cloneDeep";
import * as mutation from "./mutations-types";
import DateFilters from "@/constants/filters/date";

export default {
  [mutation.UPDATE_DATE](state, date) {
    state.date = cloneDeep(date);
  },

  [mutation.DELETE_SERVICE](state, index) {
    const services = state.services;
    services.splice(index, 1);
    state.services = [...services];
  },

  [mutation.ADD_ALL_SERVICES](state, services) {
    state.services = [...services];
  },

  [mutation.ADD_SERVICE](state, service) {
    state.services = [service].concat(state.services);
  },

  [mutation.UPDATE_SERVICE](state, { index, service }) {
    const services = state.services;
    services[index] = Object.assign({}, service);
    state.services = [...services];
  },

  [mutation.UPDATE_SERVICE_STATUS](state, { index, status }) {
    state.services = state.services.map((item, i) => {
      if (index === i) {
        item.status = status;
      }
      return item;
    });
  },

  [mutation.UPDATE_PREVIOUS_FILTER](state, filterType) {
    state.previousFilter = { ...filterType };
  },

  [mutation.UPDATE_FILTER](state, filter) {
    state.filter = { ...filter };
  },

  [mutation.UPDATE_FILTER_CITY](state, cityId) {
    state.filter.cityId = cityId;
  },

  [mutation.UPDATE_FILTER_HAS_STATISTICS_FILTERS](state, hasStatisticsFilter) {
    state.filter.hasStatisticsFilter = hasStatisticsFilter;
  },

  [mutation.UPDATE_FILTER_SERVICE_TYPE](state, serviceType) {
    state.filter.serviceType = serviceType;
  },

  [mutation.UPDATE_FILTER_STATUS](state, status) {
    state.filter.status = status;
  },

  [mutation.UPDATE_FILTER_VEHICLE_TYPE](state, vehicleType) {
    state.filter.vehicleType = vehicleType;
  },

  [mutation.UPDATE_FILTER_DATE](state, dateType) {
    state.filter.date = dateType;
  },

  [mutation.UPDATE_FILTER_PAYMENT_METHOD](state, payload) {
    state.filter.paymentMethod = payload;
  },

  [mutation.UPDATE_SEARCH_TEXT](state, searchText) {
    state.searchText = searchText;
  },

  [mutation.ADD_MULTIPLE_SERVICES](state, services) {
    state.services = [...services, ...state.services];
  },

  [mutation.UPDATE_TOTAL_SERVICES](state, total) {
    state.totalServices = total;
  },

  [mutation.RESET_FILTERS](state) {
    state.filter.status = null;
    state.filter.date = DateFilters.NUM_THIS_WEEK;
    state.filter.serviceType = null;
    state.searchText = "";
  }
};

export const SET_LISTING = "SET_LISTING";
export const UPDATE_LISTING_FILTER_SEARCH = "UPDATE_LISTING_FILTER_SEARCH";
export const UPDATE_LISTING_FILTER_CLIENT = "UPDATE_LISTING_FILTER_CLIENT";
export const UPDATE_LISTING_FILTER_COLUMNS = "UPDATE_LISTING_FILTER_COLUMNS";
export const UPDATE_LISTING_FILTER_DRIVER = "UPDATE_LISTING_FILTER_DRIVER";
export const UPDATE_LISTING_FILTER_SORT_ORDER =
  "UPDATE_LISTING_FILTER_SORT_ORDER";
export const UPDATE_LISTING_FILTER_DATE = "UPDATE_LISTING_FILTER_DATE";
export const UPDATE_LISTING_FILTER_DATE_FROM =
  "UPDATE_LISTING_FILTER_DATE_FROM";
export const UPDATE_LISTING_FILTER_DATE_UNTIL =
  "UPDATE_LISTING_FILTER_DATE_UNTIL";
export const UPDATE_LISTING_FILTER_SERVICE = "UPDATE_LISTING_FILTER_SERVICE";
export const UPDATE_LISTING_FILTER_TYPE = "UPDATE_LISTING_FILTER_TYPE";
export const UPDATE_LISTING_TOTAL = "UPDATE_LISTING_TOTAL";
